import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-parallax";

export const SectionRewards = ({ images, isVisible }) => {
    return (
        <Parallax
            blur={0}
            bgImage={require("../../assets/img/common/img_banner_download_app.jpeg")}
            bgImageAlt="BG Download App"
            strength={200}
            className="section-app"
        >
            <Container style={{ paddingTop: "4rem" }}>
                <Row>
                    <Col md={7} className="content-center">
                        <div>
                            <img
                                src={images.brand["img_logo_uplus.png"]}
                                alt="apps-store"
                                className="img-rewards mb-3"
                            />
                            <p className="animated fadeInUp delayp2">
                                U+Rewards is The Union Group’s exclusive loyalty program. Join
                                to start earning points and get exclusive privileges at all The
                                Union Group establishments.
                            </p>
                            <div className="mt-4 download-placeholder animated fadeInUp delayp3">
                                <a
                                    href="https://apps.apple.com/us/app/u-rewards/id1445510338"
                                    target="_blank"
                                    className="mr-3"
                                >
                                    <img
                                        src={images.common["button_appstore.png"]}
                                        alt="apps-store"
                                        className="img-fluid"
                                    />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.uniongroup.union"
                                    target="_blank"
                                    className="mr-3"
                                >
                                    <img
                                        src={images.common["button_playstore.png"]}
                                        alt="play-store"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col md={5} className="order-lg-first mt-4">
                        <img
                            src={images.common["img_device.png"]}
                            alt="phone"
                            className="img-fluid img-device animated fadeInUp delayp1"
                        />
                    </Col>
                </Row>
            </Container>
            {/* <div style={{ height: '400px' }} /> */}
        </Parallax>
    );
};