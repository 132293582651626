import React from "react";
import Slider from "react-slick";

export const SectionCover = ({ img }) => {
    const settings = {
        className: "carousel-cover-home",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
    };
    return (
        <div
            className={`cover cover-restaurants animated ${img ? 'fadeIn' : ''} delayp4`}
            style={{
                backgroundImage: `url(${img})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        ></div>
    )
}