import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import windowSize from 'react-window-size';
//context 
import PageContextProvider from './context/PageContext';
import Footer from './include/Footer';
//include
import NavbarMain from './include/Navbar';
import Routes from './routes';



function App() {
  const [windowWidth, setWindowWidth] = useState("");
  const handleWindowSize = () => {
    setWindowWidth({
      windowWidth: window.innerWidth
    })
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSize);
  }, []);
  const navbarWidthTrack = windowWidth.windowWidth > 767 ? "desktop" : "mobile";
  return (
    <Router>
      <ScrollToTop>
        <PageContextProvider>
          <NavbarMain navbarWidthTrack={navbarWidthTrack} />
          <Routes />
          <Footer />
        </PageContextProvider>
      </ScrollToTop>
    </Router>
  );
}

export default windowSize(App);
