import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "leaflet/dist/leaflet.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import '../src/assets/scss/main.scss';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql
} from "@apollo/client";

const client = new ApolloClient({
    uri: 'https://cms-urea-lss9.antikode.dev/wp/graphql',
    cache: new InMemoryCache()
});

const tagManagerArgs = {
    gtmId: 'GTM-5WFCGTV',
    dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
