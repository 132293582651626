import React, { useContext, useEffect } from 'react';
import { PageContext } from './../../context/PageContext';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {  Parallax } from 'react-parallax';

const Register = () => {
    const { brand, common, sample, handleNavLoad } = useContext(PageContext);
    const images = { brand, common, sample };
    useEffect(()=>{
        handleNavLoad(false)
    },[]);
    return (
        <>
            <Parallax
                blur={0}
                bgImage={require('../../assets/img/common/img_banner_download_app.jpeg')}
                bgImageAlt="the cat"
                strength={200}
                className="animated fadeInUp"
            >
                <Container style={{paddingTop: "4rem"}} className="text-center py-main">
                    <img src={images.brand["img_logo_uplus.png"]} alt="rewards" className="img-fluid d-block mx-auto w-400px mb-5 animated fadeInUp delayp1" />
                    <img src={images.brand["img_logo_tug.png"]} alt="rewards" className="img-fluid d-block mx-auto w-125px animated fadeInUp delayp2" />
                </Container>
                {/* <div style={{ height: '400px' }} /> */}
            </Parallax>
            <section className="background-texture-golden py-main">
                <Container className="container-sm">
                    <h4 className="text-center animated fadeInUp delayp3">Register</h4>
                    <div className="limiter animated fadeInUp delayp4">
                        <div className="limiter-content">
                        <p className="mb-0">Or</p>
                        </div>
                    </div>
                    <Form className=" animated fadeInUp delayp5">
                        <Form.Group>
                            <Form.Label>Email or Mobile Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Your Email or mobile number (624567xxx)"
                                className="border-0"
                            />
                            <Form.Text className="text-right">
                            Using overseas number?
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter your password"
                                className="border-0"
                            />
                        </Form.Group>
                        <Form.Group>
                            <div className="btn-placeholder mb-3">
                                <p className="font-weight-light mb-3">By Registering, you agree to our <span className="font-weight-bold">Term of Service</span> & <span className="font-weight-bold">Privacy Policy</span> </p>
                                <button className="btn btn-dark">Register</button>
                            </div>
                        </Form.Group>
                    </Form>
                </Container>
            </section>
        </>
    )
}

export default Register;