import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import TrackVisibility from "react-on-screen";
import Slider from "react-slick";
import { PageContext } from "./../../context/PageContext";
import { SectionAbout } from "./SectionAbout";
import { SectionRoom } from "./SectionRoom";
import { SectionWhatsOn } from "./SectionWhatsOn";
import { SectionRewards } from "./SectionRewards";
import { SectionSendInfo } from "./SectionSendInfo";
import { SectionCover } from "./SectionCover";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "Union-Group",
    page: "Home",
  },
  dataLayerName: "PageDataLayer",
};
const GET_HOME = gql`
  query HomePage {
    page(id: "tug-home-navigation", idType: URI) {
      homePage {
        headline
        description
        middleBanner {
          description
          name
          order
          image {
            sourceUrl
            title
            altText
          }
        }
        topBanner {
          order
          image {
            sourceUrl
            title
            altText
          }
        }
      }
    }
  }
`

const Home = () => {
  TagManager.dataLayer(tagManagerArgs);

  const { brand, common, sample, whatson, handleNavLoad, handlePageLoad } =
    useContext(PageContext);
  const images = { brand, common, sample, whatson };

  const { data } = useQuery(GET_HOME);
  const topBanner = data?.page.homePage?.topBanner?.map((item) => item).sort((a, b) => a?.order - b?.order);
  const headline = data?.page.homePage?.headline;
  const description = data?.page.homePage?.description;
  const middleBanner = data?.page.homePage?.middleBanner?.map((item) => item).sort((a, b) => a?.order - b?.order);

  useEffect(() => {
    handleNavLoad(true);
    handlePageLoad("home");
  }, []);
  return (
    <>
      <SectionCover data={topBanner} />
      <SectionAbout images={images} description={description} headline={headline} />
      <TrackVisibility once offset={100}>
        <SectionRoom images={images} data={middleBanner || []} />
      </TrackVisibility>
      <TrackVisibility once>
        {/* <SectionWhatsOn images={images} /> */}
      </TrackVisibility>
      <TrackVisibility once>
        <SectionRewards images={images} />
      </TrackVisibility>
      <a
        href="https://cho.pe/UnionGroup"
        target="_blank"
        className="btn btn-primary btn-book-now-mobile"
      >
        Book Now
      </a>
      <SectionSendInfo />
    </>
  );
};

export default Home;
