import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Navbar, NavDropdown, Nav, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { PageContext } from "./../context/PageContext";

const GET_PICKUP_DELIVERY = gql`
  query HomePage {
    page(id: "tug-home-navigation", idType: URI) {
      homePage {
        pickupMenubar {
          name
          url
          order
        }
      }
    }
  }
`
const GET_RESTAURANT = gql`
  query GetRestaurant {
    brands {
      nodes {
        slug
        title
        brands {
          order
        }
      }
    }
  }
`

const NavbarMain = ({ navbarWidthTrack }) => {
  let { pathname } = useLocation();
  const { data } = useQuery(GET_PICKUP_DELIVERY);
  const restoData = useQuery(GET_RESTAURANT);
  const [cond, setCond] = useState({
    active: "",
  });
  const { brand, common, sample, returnNav, auth, restaurantLoad, pageLoad } =
    useContext(PageContext);
  const images = { brand, common, sample };
  const pickupDelivery = data?.page?.homePage?.pickupMenubar?.map((item) => item).sort((a, b) => a?.order - b?.order)
  const restoLink = restoData?.data?.brands?.nodes?.map((item) => ({
    ...item,
    link: item.slug,
    order: item.brands.order
  })).sort((a, b) => a?.order - b?.order)
  const delivery = [
    {
      title: "Bistecca, CAFFÈ MILANO & ROMA",
      value: "Bistecca",
      url: "https://hungree.id/bisteccamilanoroma",
    },
    {
      title: "Benedict & CSCC",
      value: "corknscrewcc",
      url: "https://hungree.id/benedictcscc",
    },
    {
      title: "UNION",
      value: "union",
      url: "https://hungree.id/unionjkt",
    },
    {
      title: "LOEWY",
      value: "loewy",
      url: "https://hungree.id/loewyjakarta",
    },
    {
      title: "Feast At Home",
      value: "feastAtHome",
      url: "https://uniongroupjakarta.com/feastathome.pdf",
    },
  ];
  const navCase = (arg) => {
    const navMobile = document.querySelector(".nav-mobile");
    const navToggler = document.querySelector(".navbar-toggler");
    const dropdownNavbar = document.querySelector(".dropdown-menu");

    switch (arg) {
      case "navSide":
        navToggler.classList.toggle("is-open");
        navMobile.classList.toggle("is-open");
        break;

      case "home":
        navToggler.classList.toggle("is-open");
        navMobile.classList.toggle("is-open");
        break;

      case "whatson":
        navToggler.classList.toggle("is-open");
        navMobile.classList.toggle("is-open");
        break;

      case "feastathome":
        navToggler.classList.toggle("is-open");
        navMobile.classList.toggle("is-open");
        break;

      case "rewards":
        navToggler.classList.toggle("is-open");
        navMobile.classList.toggle("is-open");
        break;

      case "navSmDropdown":
        const navDropdown = document.querySelector(".dropdown-m");
        navDropdown.classList.toggle("is-open");
        break;

      case "navSmDropdownPickUpDelivery":
        const navDropdownPickUp = document.querySelector(
          ".dropdown-m-delivery"
        );
        navDropdownPickUp.classList.toggle("is-open");
        break;

      case "navSmDropdownFeastAtHome":
        const navDropdownFeastAtHome =
          document.querySelector(".dropdown-m-feast");
        navDropdownFeastAtHome.classList.toggle("is-open");
        break;

      // case "navSmDropdownDelivery":
      //   const navDropdownDelivery = document.querySelector(".dropdown-m-delivery");
      //   navDropdownDelivery.classList.toggle("is-open");
      //   break;

      case arg:
        setCond({
          active: arg,
        });
        dropdownNavbar.classList.remove("show");
        break;

      default:
        return null;
    }
  };
  useEffect(() => {
    let navbar = document.querySelector(".navbar");
    const uniongroupIcon = document.querySelector(".uniongroup-icon");
    const chaingroupIcon = document.querySelector(".chaingroup-icon");
    if (returnNav) {
      window.onscroll = function () {
        if (window.pageYOffset > 5) {
          uniongroupIcon.classList.add("is-scroll");
          chaingroupIcon.classList.add("is-scroll");
          navbar.classList.add("navbar-scroll");
        } else {
          uniongroupIcon.classList.remove("is-scroll");
          chaingroupIcon.classList.remove("is-scroll");
          navbar.classList.remove("navbar-scroll");
        }
      };
    }
  }, []);
  if (returnNav) {
    return (
      <>
        <Navbar fixed="top" expand="md" className="navbar-union">
          <Container>
            <div>
              <Link to="/" className="navbar-brand">
                <img
                  src={images.brand["logo_uniongroup-dark.png"]}
                  alt="union-logo"
                  className="img-fluid uniongroup-icon"
                />
                <img
                  src={images.brand["logo_rantai.png"]}
                  alt="chain-logo"
                  className="img-fluid chaingroup-icon"
                />
              </Link>
              {/* <Link to="#" className="btn btn-primary btn-nav-sm">
                Book Now
              </Link> */}
              <button
                class={"navbar-toggler " + navbarWidthTrack}
                type="button"
                onClick={() => navCase("navSide")}
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
            </div>
            <Navbar.Collapse className="justify-content-center align-items-center">
              <Nav>
                <NavDropdown title="Restaurants" id="basic-nav-dropdown">
                  {restoLink?.map((value, index) => {
                    return (
                      <Link
                        to={"/restaurant/" + value.link}
                        key={index}
                        className={`dropdown-item ${pathname.split('/restaurant/')[1] === value.link ? "active" : ""}`}
                        onClick={() => navCase(value.link)}>
                        {value.title}
                      </Link>
                    )
                  })}
                </NavDropdown>
                <div className="nav-item">
                  <a
                    href="https://uniongroupjakarta.com/feastathome.pdf"
                    target="_blank"
                    className="nav-link"
                  >
                    Feast At Home
                  </a>
                </div>
                <NavDropdown title="Pick Up / Delivery" id="basic-nav-dropdown">
                  {pickupDelivery && pickupDelivery.map((value, i) => {
                    return (
                      <a
                        href={value.url}
                        className="dropdown-item"
                        // onClick={() => navCase(value.value)}
                        key={i}
                        target="_blank"
                      >
                        {value.name}
                      </a>
                    );
                  })}
                </NavDropdown>
                {/* <NavDropdown
                  title="Delivery"
                  id="basic-nav-dropdown"
                >
                  {
                    delivery.map((value, i)=>{
                      return (
                        <a
                          href={value.url}
                          className={"dropdown-item " + value.value}
                          onClick={() => navCase(value.value)}
                          key={i}
                          target="_blank"
                        >
                          {value.title}
                        </a>
                      )
                    })
                  }
                </NavDropdown> */}
                <div className="nav-item">
                  <Link to="/whats-on" className="nav-link">
                    What's On
                  </Link>
                </div>
                <div className="nav-item nav-item-rewards">
                  <Link to="/rewards" className="nav-link">
                    <img
                      src={images.brand["logo_reward.png"]}
                      alt="rewards-logo"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </Nav>
            </Navbar.Collapse>
            {pageLoad === "rewards" ? (
              <>
                <a
                  href="https://uniongroupjakarta.com/loyalty/login/"
                  className="btn btn-auth btn-navbar font-weight-bold"
                  style={{ fontSize: ".8rem" }}
                >
                  Login
                </a>
                <a
                  href="https://uniongroupjakarta.com/loyalty/register/"
                  className="btn btn-auth btn-primary btn-navbar"
                >
                  Register
                </a>
              </>
            ) : pageLoad === "restaurant" ? (
              <>
                <a
                  href={restaurantLoad?.url}
                  target="_blank"
                  className="btn btn-primary btn-navbar"
                  style={{
                    visibility: restaurantLoad?.url == "#" ? "hidden" : "inherit",
                  }}
                >
                  {restaurantLoad?.text}
                </a>
              </>
            ) : pageLoad === "home" || "whatson" ? (
              <>
                <a
                  href="https://cho.pe/UnionGroup"
                  target="_blank"
                  className="btn btn-primary btn-navbar"
                >
                  Book Now
                </a>
              </>
            ) : (
              <></>
            )}
          </Container>
          <div
            className={
              "nav-mobile background-texture-white " + navbarWidthTrack
            }
          >
            <div className="container">
              <ul>
                <li className="nav-item">
                  <Link
                    to="/"
                    className="nav-link"
                    onClick={() => navCase("home")}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className="nav-link text-center"
                    onClick={() => navCase("navSmDropdown")}
                  >
                    Restaurants
                  </Link>
                  <div className="dropdown-m">
                    {restoLink && restoLink.map((value, index) => {
                      return (
                        <Link
                          to={"/restaurant/" + value.link}
                          key={index}
                          onClick={() => navCase("navSide")}
                        >
                          {value.title}
                        </Link>
                      );
                    })}
                  </div>
                </li>
                <div className="nav-item">
                  <a
                    href="https://uniongroupjakarta.com/feastathome.pdf"
                    target="_blank"
                    className="nav-link"
                  >
                    Feast At Home
                  </a>
                </div>
                <li className="nav-item">
                  <Link
                    to="#"
                    className="nav-link text-center"
                    onClick={() => navCase("navSmDropdownPickUpDelivery")}
                  >
                    Pick Up / Delivery
                  </Link>
                  <div className="dropdown-m dropdown-m-delivery">
                    {pickupDelivery && pickupDelivery.map((value, i) => {
                      return (
                        <a
                          href={value.url}
                          onClick={() => navCase("navSide")}
                          key={i}
                          target="_blank"
                        >
                          {value.name}
                        </a>
                      );
                    })}
                  </div>
                </li>
                {/* <li className="nav-item">
                  <Link
                    to="#"
                    className="nav-link text-center"
                    onClick={() => navCase("navSmDropdownDelivery")}
                  >
                    Delivery
                  </Link>
                  <div className="dropdown-m dropdown-m-delivery">
                    {
                    delivery.map((value, i)=>{
                      return (
                        <a
                          href={value.url}
                          onClick={() => navCase("navSide")}
                          key={i}
                          target="_blank"
                        >
                          {value.title}
                        </a>
                      )
                    })
                  }
                  </div>
                </li> */}
                <li className="nav-item">
                  <Link
                    to="/whats-on"
                    className="nav-link"
                    onClick={() => navCase("whatson")}
                  >
                    What's On
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/rewards"
                    className="nav-link"
                    onClick={() => navCase("rewards")}
                  >
                    U+Rewards
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Navbar>
      </>
    );
  } else {
    return <></>;
  }
};

export default NavbarMain;
