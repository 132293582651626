import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

export const SectionSendInfo = ({ }) => {
    const url =
        "https://uniongroupjakarta.us16.list-manage.com/subscribe/post?u=2c3f97d9001fa6199f01cdd4a&amp;id=2369f2657b";
    return (
        <section className="py-main home-newsletter">
            <div className="container">
                <div className="heading">
                    <h2>Stay In The Know</h2>
                    <p>
                        Sign up and get clued in on our great dining experiences, exclusive
                        news and deals{" "}
                    </p>
                </div>
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => {
                        return (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={(formData) => subscribe(formData)}
                            />
                        )
                    }}
                />
            </div>
            <div className="container container-md">
                <p className="home-newsletter-text text-center">
                    We are fully committed to keep personal data collected from you while
                    using our website confidential. Should you change your mind, you may
                    opt out by clicking unsubscribe on any email you receive from us.
                </p>
            </div>
        </section>
    );
};


const CustomForm = ({ status, message, onValidated }) => {
    let email, name;
    const submit = () =>
        email &&
        name &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
            NAME: name.value,
        });

    return (
        <div className="row row-2 form-newsletter mb-5">
            {/* {status === "sending" && <div style={{ color: "#d2ae68" }} className="status-report">sending...</div>} */}
            {status === "error" && (
                <div
                    style={{ color: "#ee3124" }}
                    className="status-report"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "#d2ae68" }}
                    className="status-report"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            <div className="col-6 col-md-4">
                <input
                    ref={(node) => (name = node)}
                    className="form-control"
                    type="text"
                    placeholder="Your name"
                />
            </div>
            <div className="col-6 col-md-4">
                <input
                    className="form-control"
                    ref={(node) => (email = node)}
                    type="email"
                    placeholder="Your email"
                />
            </div>
            <div className="col-md-4">
                <button className="btn btn-dark btn-block h-100" onClick={submit}>
                    {status === "sending" ? (
                        <i class="fa fa-circle-notch fa-spin"></i>
                    ) : status === "success" ? (
                        <i class="far fa-check"></i>
                    ) : (
                        "submit"
                    )}
                </button>
            </div>
        </div>
    );
};