import React, { useContext, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { PageContext } from './../../context/PageContext';
import TagManager from 'react-gtm-module'

import { Test } from "./../Houserules/component"

const tagManagerArgs = {
    dataLayer: {
        userId: '007',
        userProject: 'Union-Group',
        page: 'Privacy Policy'
    },
    dataLayerName: 'PageDataLayer'
}

const PrivacyPolice = () => {
    TagManager.dataLayer(tagManagerArgs)
    const { handleNavLoad } = useContext(PageContext);
    useEffect(()=>{
        handleNavLoad(true)
    },[])
    const privacyPolicy = {
        purpose: {
            title: "PURPOSE FOR COLLECTION:",
            desc: "We are fully committed to providing you with information about the collection and use of personal data furnished by, or collected from you while using our websites, mobile application, products and services. It is our standard practice not to ask you for information unless we need it. The main purpose for collecting your personal data is to allow us to provide you with the requested services, such as processing a transaction. In addition, information may be collected for the following purposes:",
            list: [
                "Fulfilling reservation or information requests;",
                "Purchasing products or services;",
                "Registering for program membership;",
                "Responding to our communications (e.g., surveys, promotional offers, or reservation confirmations);",
                "Accommodating your personal preferences;",
                "Fulfilling requests for services or recommendations we provide you;",
                "Working with third-party sources with your consent, including processing information you make available via social networking and other websites, to better assist us with understanding your interests and to serve you better;",
                "Your use of our apps on your electronic devices;",
                "Updating your contact information including your address; or",
                "Facilitating the transmission of forward to a friend email at your request."
            ],
            desc2: "We may also ask your permission to use your personal data for the following purposes:",
            list2: [
                "Sending you direct marketing messages and communications in relation to the products and services offered by The Union Group, our strategic marketing partners, and other trusted third parties;",
                "Performing market research via surveys to better serve your needs, improve the effectiveness of our websites and mobile application, your membership experience, our various types of communications and promotional campaigns and activities.",
                "We may also analyze personal data we process for the above-mentioned purposes to better understand our customers’ needs, improve our services and the way we operate. The outcome of such analysis will only be aggregate data that will not to be used to identify, single out, or act towards an individual, but only to analyze trends and other behavior at aggregate level."
            ]
        },
        typeInformation: {
            title: "TYPES OF INFORMATION WE COLLECT:",
            desc: [
                "We collect information about our customers, website visitors, and mobile application users so that we can provide an experience that is responsive to our customers’ and members’ needs. In order to fulfill the above-mentioned purposes, we may collect personal data or information relating to you that can identify you, directly or indirectly. Such data may primarily include your name, home, work and e-mail addresses, mobile telephone, credit card information, date of birth, gender, and lifestyle information such as product/service preferences, leisure activities, and other information necessary to fulfill special requests.",
                "When you visit our websites or use our mobile application, we may also collect internet log information and details of visitor behavior patterns which we only process in a way which does not identify you (or anyone else). If we use your data in this way, we do not attempt to identify you or to single out information on an individual level. If we do want to identify you or single you out, we will make it clear when we collect the information and will explain what we intend to do with it."
            ]
        },
        consent: {
            title: "CONSENT:",
            desc: [
                "You may opt out of The Union Group marketing emails relating The Union Group’s offerings and services, when we collect your information and at any time by clicking on the link included in such communications, or by contacting us directly to opt out of direct marketing, telemarketing, emails or surveys.",
                "If you are a U+REWARDS member, we will communicate to you via email on every transaction and new updates.",
                "In some cases, your permission will be implied from the nature of the service requested or transaction undertaken. For example, we may collect and use personal data that you volunteer, or behavioral data or other information collected in the aggregate. In addition, your permission will be deemed given for communications from us that are necessary to fulfill transactions and services that you request.",
                "In certain circumstances, we will seek your express permission when we collect information that is regarded under certain data privacy regulations to be sensitive in nature (e.g., information revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, sex life, health conditions or judicial data) as permitted or required by the applicable law."
            ]
        },
        transfering: {
            title: "TRANSFERING:",
            desc: [
                "In most cases, the information you provide is added to The Union Group main database. As a general practice, we do not sell, rent, or give physical possession of your personal data to unaffiliated third parties outside The Union Group system.",
                "Situations in which we may share your information to others include:"
            ],
            list: [
                "When we have received your consent to do so",
                "When companies or service providers that perform business activities on behalf of The Union Group require such information in order to conduct the tasks defined in their agreements with The Union Group (e.g., credit card processing, customer support services, market research administration or database management services),",
                "When a brand leaves The Union Group system and access to your personal data is necessary to facilitate business operations or meet contractual obligations in connection with the fulfillment of reservations that are booked for future events,",
                "In the event The Union Group is merged or acquired by another company,",
                "To comply with legal or regulatory requirements or obligations in accordance with applicable law, a court order or a subpoena,",
                "In case of an emergency such as to safeguard the life, health, or property of an individual."
            ],
            desc2: [
                "If information is shared as mentioned above, we seek to limit the scope of information that is furnished to the amount necessary for the performance of the specific purpose",
                "Unless otherwise precluded by legal process, third parties are obligated to protect your personal data in line with our policy and abide by applicable privacy laws and regulations."
            ]
        },
        deletionRetention: {
            title: "DELETION AND RETENTION OF YOUR PERSONAL DATA:",
            desc: "Your personal data will be kept in a form which enables to identify you for no longer than it is necessary for the purposes for which we collected and use your data. Your personal data may be retained in certain files for a period of time as required by applicable law and following The Union Group’s data retention policies in order to comply with such financial or legal requirements, to properly resolve disputes or to troubleshoot problems. In addition, some types of information may be stored indefinitely due to technical constraints and will be blocked from further processing for purposes which are not mandatory by law."
        },
        aur: {
            title: "ACCESSING, UPDATING AND REMOVING YOUR PERSONAL DATA:",
            desc: [
                "You may request to have access to your personal data we keep or to have your personal data updated, erased or blocked unless this proves impossible or involves a disproportionate effort. You may request confirmation as to whether we process your personal data and what that data constitutes.",
                "Where allowed under local law, you may request us to erase your personal data, if one of the following circumstances exist:"
            ],
            list: [
                "Your personal data are no longer necessary for the purposes for which they were collected or otherwise processed;",
                "if the processing was based on your consent and if you have withdrawn your consent, your consent has expired, or where there is no longer a legal ground for the processing of your personal data;",
                "If the processing is for direct marketing purposes or if you make a justifiable objection;",
                "A court or regulatory authority has ruled that your personal data concerned must be erased;",
                "Your personal data was unlawfully processed."
            ],
            desc2: [
                "To ensure that your personal data is accurate and up to date, we encourage you to regularly review and update your information as appropriate (e.g., in the event your home or e-mail address changes, or you wish to add an additional method for us to communicate with you). If you are a U+REWARDS member, you can review and update your individual membership profile on-line or with the assistance of a U+REWARDS membership’s customer service representative. If you are not a U+REWARDS member you can review and update your personal data by contacting our customer service representative via e-mail. Please note that in an effort to prevent the unauthorized disclosure of your personal data, you may be asked to provide proof of identity (or other authentication materials). Access to personal data for non-U+REWARDS member cannot be provided by telephone",
                "If, upon review, you wish to deactivate your U+REWARDS membership profile, or update your personal data, we will endeavor to do so in a timely manner. Occasionally, information that you request to be removed will be retained as explained above.",
                "Therefore, for the above reasons you should not always expect that all of your personal data will be completely removed from our databases in response to your request, even though we are regularly reviewing our data retention practices to ensure that personal data, while residing on the systems of The Union Group and/or The Union Group’s service providers, will not be actively processed and will be accessed only if required by law.",
                "Please refer to the appropriate contact information to contact us about updating your communication preferences, removal from our marketing lists, or to submit a general question or request."
            ]
        },
        security: {
            title: "SECURITY SAFEGUARDS:",
            desc: "The Union Group recognizes the importance of information security, and is constantly reviewing and enhancing our technical, physical, and logical security rules and procedures. All The Union Group owned websites and servers have security measures in place to help protect your personal data against accidental, loss, misuse, unlawful or unauthorized access, disclosure, or alteration while under our control. Although guaranteed security does not exist either on or off the Internet, we safeguard your information using appropriate administrative, procedural and technical safeguards, including password controls and firewalls."
        },
        onlineTechnology: {
            title: "ON-LINE TECHNOLOGIES:",
            desc: [
                "The Union Group and its third-party marketing partners may use cookies, invisible pixels, and web beacons to obtain information about you while visiting our websites. A cookie is a very small text file that is sent to your browser from a web server and stored on your computer's hard drive. The Union Group cookies assign your computer with a unique identifier, which in turn, becomes your identification card whenever you return to The Union Group website.",
                "Cookies are designed to save you time because they help us to provide you with a customized experience without you having to remind us of your preferences each time you return to our web pages. The Union Group cookies are not designed to damage your files, nor can they read information from other files on your computer hard drive.",
                "Cookies can also help us provide you with a personalized experience in the following ways:"
            ],
            list: [
                "Remembering your username and password for future visits so log-in is easier and faster.",
                "Ensuring you are provided with the appropriate frames and content",
                "Ensuring you obtain all requested information.",
                "Helping us deliver communications that are relevant and responsive to your interests and location."
            ],
            desc2: [
                "The Union Group cookies are limited to our sites and services only and are not designed to follow you when traveling on the Internet after leaving. We track the usage of our websites in order to better meet your needs and to help make it easier for you to find information in the future. Please note that in addition to The Union Group cookies, various third parties may also place cookies on your computer's hard drive.",
                "The Union Group is not responsible for the presence or absence of third-party cookies, or for the technological capabilities or practices employed by third parties in connection with third-party cookies.",
                "If you would prefer not to have the benefits of cookies, your Internet browser can be adjusted to treat cookies in different ways. Depending upon the type of browser you are using, you may be able to configure your browser so that:"
            ],
            list2: [
                "You are prompted to accept or reject cookies on an individual basis or,",
                "You may be able to prevent your browser from accepting any cookies at all."
            ],
            desc3: [
                "You should refer to the supplier or manufacturer of your web browser for specific details about cookie security. However, you should also understand that rejecting cookies might affect your ability to perform certain transactions on our websites and our ability to recognize your browser from one visit to the next.",
                "The Union Group also uses invisible pixels, sometimes called web beacons, to count how many people visit certain web pages. Information collected from invisible pixels is used and reported in the aggregate and does not contain personal data. The Union Group may use this information to improve marketing programs and content.",
                "We also work with vendors and strategic marketing partners to help deliver advertisements and personalized content that we believe will be of interest to you. These vendors and other partners include advertisers, advertising agencies, advertising networks, audience segment providers, data exchanges, analytics providers, and other similar providers. These partners and vendors use cookies and web beacons to collect information about your online activity (e.g., the sites and pages you have visited at The Union Group sites and other third-party sites) in order to help The Union Group (and in some cases, third party advertisers) deliver personalized content and advertising to you while you are on The Union Group or other sites.",
                "We may also engage one of these vendors to deliver our advertisements to consumers whose online behaviors on sites other than The Union Group sites indicate that they may be interested in F&B, music, and lifestyle. In other instances, we engage in special arrangements with certain advertisers with which we think our web site visitors would have an interest. We allow these advertisers to deliver co-branded messages (e.g., The Union Group and the advertiser) directly to consumers who have visited our web pages. These types of cobranded advertisements may appear on The Union Group sites or on third-party sites."
            ]
        },
        links: {
            title: "LINKS TO OTHER WEB SITES:",
            desc: "In order to anticipate your needs, our website provides links to other websites and third parties for your convenience and information. We are not responsible for the collection, use, maintenance, sharing or disclosure of data (including personal data) by such third parties. We encourage you to contact these third parties to ask questions about their privacy practices, policies and security measures before disclosing any personal data. We recommend that you review the privacy statements and policies of linked websites to understand how those websites collect, use and store information."
        },
        minors: {
            title: "MINORS:",
            desc: "We do not seek to obtain nor does it wish to receive personal data directly from minors; however, we cannot always determine the age of persons who access and use our websites. If a minor (as defined by applicable law) provides us with his/her data without parental or guardian consent, we encourage the parent or guardian to contact us to have this information removed and to unsubscribe the minor from future The Union Group marketing communications."
        }
    }
    return (
        <div className="background-texture-golden py-main">
            <Container className="container-sm">
                <Row>
                    <div className="heading center mb-3">
                        <h3 className="text-uppercase animated fadeInUp delayp1 mb-5">The Union Group Privacy Policy</h3>
                        <p className="text-gray-400 animated fadeInUp delayp2">
                            This Privacy Policy document contains types of information that is collected and recorded by The Union Group and how we use it.
                        </p>
                        <p className="text-gray-400 mb-0 animated fadeInUp delayp3">
                            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in The Union Group. This policy is not applicable to any information collected offline or via channels other than this website.
                        </p>
                    </div>
                    <div>
                        <h5 className="animated mb-3 fadeInUp delayp3">{privacyPolicy.purpose.title}</h5>
                        <p className="text-gray-400 animated fadeInUp delayp4">{privacyPolicy.purpose.desc}</p>
                        <ul className="list-primary animated fadeInUp delayp5">
                            {privacyPolicy.purpose.list.map((value, index)=>{
                                return (
                                    <li key={index}>{value}</li>
                                )
                            })}
                        </ul>
                        <p className="text-gray-400 animated fadeInUp delayp6">{privacyPolicy.purpose.desc2}</p>
                        <ul className="list-primary mb-3 animated fadeInUp delayp7">
                            {privacyPolicy.purpose.list2.map((value, index)=>{
                                return (
                                    <li key={index}>{value}</li>
                                )
                            })}
                        </ul>
                    </div>
                    <div>
                        <h5 className=" mb-3 animated fadeInUp delayp8">{privacyPolicy.typeInformation.title}</h5>
                        {privacyPolicy.typeInformation.desc.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400 animated fadeInUp delayp9">{value}</p>
                            )
                        })}
                    </div>
                    <div>
                        <h5 className="mb-3 animated fadeInUp delayp10">{privacyPolicy.consent.title}</h5>
                        {privacyPolicy.consent.desc.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400 animated fadeInUp delayp11">{value}</p>
                            )
                        })}
                    </div>
                    <div>
                        <h5 className="mb-3 animated fadeInUp delayp12">{privacyPolicy.transfering.title}</h5>
                        {privacyPolicy.transfering.desc.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400 animated fadeInUp delayp13">{value}</p>
                            )
                        })}
                        <ul className="list-primary animated fadeInUp delayp14">
                            {privacyPolicy.transfering.list.map((value, index)=>{
                                return (
                                    <li key={index}>{value}</li>
                                )
                            })}
                        </ul>
                        {privacyPolicy.transfering.desc2.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400 animated fadeInUp delayp15">{value}</p>
                            )
                        })}
                    </div>
                    <div>
                        <h5 className="mb-3 animated fadeInUp delayp16">{privacyPolicy.deletionRetention.title}</h5>
                        <p className="text-gray-400 animated fadeInUp delayp17">{privacyPolicy.deletionRetention.desc}</p>
                    </div>
                    <div>
                        <h5 className="mb-3">{privacyPolicy.aur.title}</h5>
                        {privacyPolicy.aur.desc.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400">{value}</p>
                            )
                        })}
                        <ul className="list-primary">
                            {privacyPolicy.aur.list.map((value, index)=>{
                                return (
                                    <li key={index}>{value}</li>
                                )
                            })}
                        </ul>
                        {privacyPolicy.aur.desc2.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400">{value}</p>
                            )
                        })}
                    </div>
                    <div>
                        <h5 className="mb-3">{privacyPolicy.security.title}</h5>
                        <p className="text-gray-400">{privacyPolicy.security.desc}</p>
                    </div>
                    <div>
                        <h5 className="mb-3">{privacyPolicy.onlineTechnology.title}</h5>
                        {privacyPolicy.onlineTechnology.desc.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400">{value}</p>
                            )
                        })}
                        <ul className="list-primary">
                            {privacyPolicy.onlineTechnology.list.map((value, index)=>{
                                return (
                                    <li key={index}>{value}</li>
                                )
                            })}
                        </ul>
                        {privacyPolicy.onlineTechnology.desc2.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400">{value}</p>
                            )
                        })}
                        <ul className="list-primary">
                            {privacyPolicy.onlineTechnology.list2.map((value, index)=>{
                                return (
                                    <li key={index}>{value}</li>
                                )
                            })}
                        </ul>
                        {privacyPolicy.onlineTechnology.desc3.map((value, index)=>{
                            return (
                                <p key={index} className="text-gray-400">{value}</p>
                            )
                        })}
                    </div>
                    <div>
                        <h5 className="mb-3">{privacyPolicy.links.title}</h5>
                        <p className="text-gray-400">{privacyPolicy.links.desc}</p>
                    </div>
                    <div>
                        <h5 className="mb-3">{privacyPolicy.minors.title}</h5>
                        <p className="text-gray-400">{privacyPolicy.minors.desc}</p>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default PrivacyPolice;