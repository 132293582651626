import React, { useContext, useEffect, useState } from 'react';
import { Container, Accordion, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { PageContext } from './../../context/PageContext';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    dataLayer: {
        userId: '005',
        userProject: 'Union-Group',
        page: 'Faq'
    },
    dataLayerName: 'PageDataLayer'
}

const FaQ = () => {
    TagManager.dataLayer(tagManagerArgs)
    const { handleNavLoad } = useContext(PageContext);
    useEffect(()=>{
        handleNavLoad(true)
    },[])
    const handleClick = (arg, index) => {
        let loyalti = document.getElementsByClassName("loyalti");
        let membershipAccount = document.getElementsByClassName("membership-account");
        let uPlusPoints = document.getElementsByClassName("u-plus-points");
        let benefitsRewards = document.getElementsByClassName("benefits-rewards");
        let challenges = document.getElementsByClassName("challenges");
        let additional = document.getElementsByClassName("additional");
        switch(arg) {
            case "loyalti-program":
                for(let i = 0; i < loyalti.length; i++) {
                    if(loyalti[i] != loyalti[index]) {
                        loyalti[i].classList.remove("active")
                    }else {
                        loyalti[i].classList.toggle("active");
                    }
                }
                break;

            case "membership-account":
                for(let i = 0; i < membershipAccount.length; i++) {
                    if(membershipAccount[i] != membershipAccount[index]) {
                        membershipAccount[i].classList.remove("active")
                    }else {
                        membershipAccount[i].classList.toggle("active");
                    }
                }
                break;

            case "u-plus-points":
                for(let i = 0; i < uPlusPoints.length; i++) {
                    if(uPlusPoints[i] != uPlusPoints[index]) {
                        uPlusPoints[i].classList.remove("active")
                    }else {
                        uPlusPoints[i].classList.toggle("active");
                    }
                }
                break;

            case "benefits-rewards":
                    for(let i = 0; i < benefitsRewards.length; i++) {
                        if(benefitsRewards[i] != benefitsRewards[index]) {
                            benefitsRewards[i].classList.remove("active")
                        }else {
                            benefitsRewards[i].classList.toggle("active");
                        }
                    }
                break;

            case "challenges":
                    for(let i = 0; i < challenges.length; i++) {
                        if(challenges[i] != challenges[index]) {
                            challenges[i].classList.remove("active")
                        }else {
                            challenges[i].classList.toggle("active");
                        }
                    }
                break;

            case "additional":
                    for(let i = 0; i < additional.length; i++) {
                        if(additional[i] != additional[index]) {
                            additional[i].classList.remove("active")
                        }else {
                            additional[i].classList.toggle("active");
                        }
                    }
                break;

            default:
                return null;
        }
    }
    const dataAccordion = {
        loyaltiProgram: [
            {
                title: "WHAT IS U+REWARDS?",
                desc: "U+REWARDS is The Union Group’s exclusive loyalty program that allows members to collect U+Points for every transaction made, earn bonus U+Points from accomplishing challenges, and redeem U+Points for exciting rewards. Members can enjoy exclusive benefits as soon as they join the program, and get more benefits as they level up.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "HOW MUCH DOES IT COST TO BECOME A U+REWARDS MEMBER?",
                desc: "U+REWARDS membership is free.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "WILL I BE GIVEN A MEMBERSHIP CARD AFTER JOINING U+REWARDS LOYALTY PROGRAM?",
                desc: "U+REWARDS loyalty program is cardless. All activities will be done through The Union Group mobile app. Once you register and verify your mobile number, you are good to go. Simply tell your mobile number or member ID to our staff every time you visit our establishments.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "WHERE CAN I USE THIS MEMBERSHIP?",
                desc: "You can use your U+REWARDS membership at all The Union Group establishments.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "WHAT ARE MY BENEFITS AS A U+REWARDS MEMBER?",
                desc: "Member benefits are varied based on the membership level. All member benefits can be viewed in the benefits page through:",
                list: {
                    type: "A",
                    content: [
                        "The Union Group mobile app by going to “Profile” and then click on “Benefits”.",
                        "The Union Group website by going to U+Rewards. All member benefits will appear on the bottom page."
                    ]
                }
            },
            {
                title: "HOW MANY LEVELS OF MEMBERSHIP ARE THERE IN U+REWARDS AND WHAT DO I NEED TO DO TO ACHIEVE THE HIGHER LEVEL?",
                desc: "There are three membership levels in U+REWARDS: SILVER, GOLD, and BLACK. Upon registering, you will become a SILVER member automatically. To become a GOLD member, you have to spend at least IDR 100.000 and to become a BLACK member you have to spend at least IDR 1.000.000 within one calendar year. When you reach the spending qualification, your membership level will be upgraded automatically. Spending is calculated cumulatively in one year period.",
                list: {
                    type: "",
                    content: []
                }
            },
        ],
        membershipAccount: [
            {
                title: "HOW DO I CHECK MY TRANSACTIONS?",
                descWmail: {
                    firstText: "You can check your transaction history by logging into U+REWARDS account on The Union Group mobile app and follow these steps:",
                    email: "",
                    lastText: ""
                },
                list: {
                    type: "1",
                    content: [
                        "Go to your “Profile”",
                        "Click on “History”"
                    ]
                }
            },
            {
                title: "MY MEMBERSHIP LEVEL HAS BEEN DOWNGRADED. WHY AND HOW TO MAINTAIN MY MEMBERSHIP LEVEL?",
                descWmail: {
                    firstText: "U+REWARDS membership level will be adjusted every 2nd of January each year. To keep enjoying exclusive benefits of your current membership level, you will have to maintain your annual spending to meet each level qualification, which is IDR 15,000,000 for GOLD and IDR 50,000,000 for BLACK. Total spending will also be reset every 2nd of January each year.",
                    email: "",
                    lastText: ""
                },
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "HOW TO CHANGE MY ACCOUNT PASSWORD?",
                descWmail: {
                    firstText: "You can change your account password through",
                    email: "",
                    lastText: ""
                },
                list: {
                    type: "A",
                    content: [
                        "The Union Group mobile app by following these steps: go to “Profile”, click on “Settings”, and then select “Change Password”.",
                        "The Union Group website by logging into your U+REWARDS account, go to “Settings”, and then click on “Password”."
                    ]
                }
            },
            {
                title: "WHAT SHOULD I DO IF I FORGOT THE PASSWORD TO MY ACCOUNT?",
                descWmail: {
                    firstText: "You can create a new password by following these steps",
                    email: "",
                    lastText: ""
                },
                list: {
                    type: "",
                    content: [
                        "Go to the login page on The Union Group mobile app or website",
                        "Click on “Forgot Password”",
                        "Fill in your mobile number and then click on “Reset Password”",
                        "A reset link to create a new password will be sent to your mobile number"
                    ]
                }
            },
            {
                title: "DOES MY U+REWARDS ACCOUNT EXPIRE?",
                descWmail: {
                    firstText: "No, your U+REWARDS account does not expire.",
                    email: "",
                    lastText: ""
                },
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "CAN SOMEONE ELSE USE MY U+REWARDS ACCOUNT TO EARN U+POINTS?",
                descWmail: {
                    firstText: "You are fully responsible for your U+REWARDS membership information. U+Points can be earned simply by informing the valid U+REWARDS member ID or mobile number.",
                    email: "",
                    lastText: ""
                },
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "WHAT SHOULD I DO IF MY ACCOUNT CAN’T BE USED AT THE TIME OF PURCHASE?",
                descWmail: {
                    firstText: "Should there be any error in your account at the time of purchase, our staff will assist you to fill out a form to send to our customer support or you may contact our customer service via email",
                    email: "info@uniongroupjakarta.com",
                    lastText: "or phone number 021-2510191 (Monday - Friday 10 am - 6 pm)."
                },
                list: {
                    type: "",
                    content: []
                }
            },
        ],
        uPoint: [
            {
                title: "WHAT IS U+POINTS?",
                desc: "U+Points is a point currency used in U+REWARDS loyalty program as a reward from The Union Group for members purchases.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "HOW TO EARN U+POINTS?",
                desc: "Member will get 1 (one) U+Point for every IDR 10,000 spent at all The Union Group establishments. U+Points given will be calculated based on the subtotal of your transaction before tax and services. Member can also earn U+Points by completing challenges. U+Points can be redeemed for exclusive rewards.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "HOW DO I CHECK MY U+POINTS BALANCE?",
                desc: "Simply log in using your registered phone number and password to your U+REWARDS account on The Union Group mobile app or website. Your U+Points balance will appear on the “Home” and “Profile” page.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "HOW SOON WILL I GET U+POINTS AFTER EACH TRANSACTION?",
                desc: "Please allow up to 24 hours for us to process your transaction into U+Points.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "HOW DO I REDEEM MY U+POINTS?",
                desc: "U+Points can be redeemed for exclusive rewards through The Union Group mobile app by following these steps",
                list: {
                    type: "",
                    content: [
                        "Go to “Rewards” page to browse all available rewards. You can click on each reward title to see the details.",
                        "Click on “Redeem” and then “OK”",
                        "An e-voucher will be issued and can be found in Rewards > My Voucher"
                    ]
                }
            },
            {
                title: "DO U+POINTS EXPIRE?",
                desc: "U+Points that you have collected will expire 1 (one) year from the end of transaction month. Example: if you collect U+Points on 13 October 2019, the U+Points will be expired on October 31, 2020.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "CAN MY U+POINTS BE EXCHANGED FOR CASH OR TRANSFERRED TO OTHER U+REWARDS ACCOUNT?",
                desc: "No, your U+Points can’t be exchanged for cash or transferred to another U+REWARDS account.",
                list: {
                    type: "",
                    content: []
                }
            }
        ],
        benefitsRewards: [
            {
                title: "HOW DO I USE MY REWARDS?",
                desc: "Your redeemed rewards can be viewed in “My Vouchers” on the “Rewards” page. You can use your vouchers by following these steps:",
                list: {
                    type: "",
                    content: [
                        "Choose the voucher you want to use, click “Use” > “OK” and then inform our staff.",
                        "Make sure you click “Use” only when you are in the outlet you wish to use your voucher in."
                    ]
                }
            },
            {
                title: "HOW DO I GET MY BIRTHDAY TREAT?",
                desc: "Simply tell your member ID or mobile number to our staff and mention that it is your birthday and you want to claim your birthday benefit. Our system will recognize your birthday and our staff will serve your birthday treat.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "HOW MANY TIMES CAN I CLAIM MY BIRTHDAY BENEFITS?",
                desc: "Complimentary sweet treat can only be used once in each outlet and will be given only on the customer’s birthday date. Birthday week discount can only be used once in each outlet between the birthday date and six days after.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "CAN I COMBINE OTHER PROMOTIONS WITH THE BENEFITS OF U+REWARDS LOYALTY PROGRAM?",
                desc: "Our system only allows one promotion to be applied to one product. If you have two promotions that do not affect the same product, you can combine the two promotions. If the two promotions apply to the same product, you will have to choose only one of the promotion.",
                list: {
                    type: "",
                    content: []
                }
            }
        ],
        challenges: [
            {
                title: "WHAT IS CHALLENGE?",
                desc: "Challenge is a specially designed feature that allows U+REWARDS members to earn bonus U+Points upon completion of a series of activity within a period of time.",
                list: {
                    type: "",
                    content: []
                }
            },
            {
                title: "HOW TO PARTICIPATE IN A CHALLENGE?",
                desc: "In order to start the Challenge, member has to activate it first by following these steps:",
                list: {
                    type: "",
                    content: [
                        "Go to “Challenge” page and click on challenge title to see the details.",
                        "Click “Activate” button to start and follow the instructions."
                    ]
                }
            }
        ],
        additional: [
            {
                title: "WHAT SHOULD I DO IF I HAVE A QUESTION OR PROBLEM REGARDING MY U+REWARDS ACCOUNT?",
                descWmail: {
                    firstText: "You can contact our customer service via email",
                    email: "info@uniongroupjakarta.com",
                    lastText: "or by phone number +62212510611 (Monday - Friday 10 AM - 6 PM)."
                },
                list: {
                    type: "",
                    content: []
                }
            }
        ]
    }
    return (
        <>
            <div className="background-texture-golden py-main">
                <Container className="container-sm">
                    <div className="heading text-center mb-5">
                        <h2 className="text-uppercase animated fadeInUp delayp1">Frequently Asked Questions</h2>
                        {/* <h4 className="text-uppercase animated fadeInUp delayp1">faq</h4> */}
                    </div>
                    <div className="heading w-border-bottom-50 animated fadeInUp delayp2">
                        <p className="text text-uppercase font-weight-bold">U+REWARDS Loyalty Program</p>
                    </div>
                    <Accordion defaultActiveKey="0" className="animated fadeInUp delayp3">
                        {dataAccordion.loyaltiProgram.map((value, index)=>{
                            const sumDelay = index+1;
                            return (
                                <Card key={index} className="card-accordion-transparent w-arrow">
                                    <Card.Header className="loyalti" onClick={()=>handleClick("loyalti-program", index)}>
                                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                            {value.title}
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <p>{value.desc}</p>
                                            {value.list.content.length > 0 ?
                                                <ol type={value.list.type}>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ol>
                                                :
                                                <></>
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                    <div className="heading w-border-bottom-50 animated fadeInUp delayp4">
                        <p className="text text-uppercase font-weight-bold">Membership & Account</p>
                    </div>
                    <Accordion defaultActiveKey="0" className="animated fadeInUp delayp4">
                        {dataAccordion.membershipAccount.map((value, index)=>{
                            const sumDelay = index+1;
                            return (
                                <Card key={index} className="card-accordion-transparent w-arrow">
                                    <Card.Header className="membership-account" onClick={()=>handleClick("membership-account", index)}>
                                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                            {value.title}
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <p>
                                                {value.descWmail.firstText} {" "}
                                                <a href={"mailto:"+value.descWmail.email}> 
                                                {value.descWmail.email} {" "}
                                                </a>
                                                {value.descWmail.lastText}
                                            </p>
                                            {value.list.content.length > 0 ?
                                                value.list.type == "" ?
                                                
                                                <ul>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ul>
                                                :
                                                <ol type={value.list.type}>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ol>
                                                :
                                                <></>
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                    <div className="heading w-border-bottom-50 animated fadeInUp delayp4">
                        <p className="text text-uppercase font-weight-bold">U+Points</p>
                    </div>
                    <Accordion defaultActiveKey="0" className="animated fadeInUp delayp5">
                        {dataAccordion.uPoint.map((value, index)=>{
                            return (
                                <Card key={index} className="card-accordion-transparent w-arrow">
                                    <Card.Header className="u-plus-points" onClick={()=>handleClick("u-plus-points", index)}>
                                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                            {value.title}
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <p>{value.desc}</p>
                                            {value.list.content.length > 0 ?
                                                value.list.type == "" ?
                                                
                                                <ul>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ul>
                                                :
                                                <ol type={value.list.type}>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ol>
                                                :
                                                <></>
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                    <div className="heading w-border-bottom-50 animated fadeInUp delayp4">
                        <p className="text text-uppercase font-weight-bold">Benefits & Rewards</p>
                    </div>
                    <Accordion defaultActiveKey="0" className="animated fadeInUp delayp7">
                        {dataAccordion.benefitsRewards.map((value, index)=>{
                            return (
                                <Card key={index} className="card-accordion-transparent w-arrow">
                                    <Card.Header className="benefits-rewards" onClick={()=>handleClick("benefits-rewards", index)}>
                                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                            {value.title}
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <p>{value.desc}</p>
                                            {value.list.content.length > 0 ?
                                                value.list.type == "" ?
                                                <ul>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ul>
                                                :
                                                <ol type={value.list.type}>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ol>
                                                :
                                                <></>
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                    <div className="heading w-border-bottom-50 animated fadeInUp delayp4">
                        <p className="text text-uppercase font-weight-bold">Challenges</p>
                    </div>
                    <Accordion defaultActiveKey="0" className="animated fadeInUp delayp9">
                        {dataAccordion.challenges.map((value, index)=>{
                            return (
                                <Card key={index} className="card-accordion-transparent w-arrow">
                                    <Card.Header className="challenges" onClick={()=>handleClick("challenges", index)}>
                                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                            {value.title}
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <p>{value.desc}</p>
                                            {value.list.content.length > 0 ?
                                                value.list.type == "" ?
                                                <ul>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ul>
                                                :
                                                <ol type={value.list.type}>
                                                    {value.list.content.map((value, index)=>{
                                                        return (
                                                            <li key={index}>{value}</li>
                                                        )
                                                    })}
                                                </ol>
                                                :
                                                <></>
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                    <div className="heading w-border-bottom-50 animated fadeInUp delayp4">
                        <p className="text text-uppercase font-weight-bold">Additional</p>
                    </div>
                    <Accordion defaultActiveKey="0" className="animated fadeInUp delayp11">
                        {dataAccordion.additional.map((value, index)=>{
                            return (
                                <Card key={index} className="card-accordion-transparent w-arrow">
                                    <Card.Header className="additional" onClick={()=>handleClick("additional", index)}>
                                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                            {value.title}
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <p>
                                                {value.descWmail.firstText} {" "}
                                                <a href={"mailto:"+value.descWmail.email}> 
                                                {value.descWmail.email} {" "}
                                                </a>
                                                {value.descWmail.lastText}
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                    <div className="btn-placeholder animated fadeInUp delayp12">
                        <Link to="/contact" className="btn btn-dark" style={{width: "200px"}}>Contact Us</Link>
                    </div>
                </Container>
            </div>
        </>
    )
}


export default FaQ;