import React, { useState, useEffect, createContext } from "react";

export const PageContext = createContext();
const PageContextProvider = ({ children }) => {
  const [cond, setCond] = useState({
    brand: [],
    common: [],
    sample: [],
    whatson: [],
    ico: [],
    restaurant: [],
    returnNav: true,
    menuBenedict: []
  });
  let [restaurantLoad, setRestaurantLoad] = useState("");
  let [footerCase, setFooterCase] = useState(true);
  let [pageLoad, setPageLoad] = useState("");
  // START - IMPORT ASSETS IMAGES
  const importBrand = e => {
    let img = {};
    e.keys().forEach((item, index) => {
      img[item.replace("./", "")] = e(item);
    });
    return img;
  };

  const importCommon = e => {
    let img = {};
    e.keys().forEach((item, index) => {
      img[item.replace("./", "")] = e(item);
    });
    return img;
  };

  const importIco = e => {
    let img = {};
    e.keys().forEach((item, index) => {
      img[item.replace("./", "")] = e(item);
    });
    return img;
  };

  const importSample = e => {
    let img = {};
    e.keys().forEach((item, index) => {
      img[item.replace("./", "")] = e(item);
    });
    return img;
  };
  const importWhatsOn = e => {
    let img = {};
    e.keys().forEach((item, index) => {
      img[item.replace("./", "")] = e(item);
    });
    return img;
  };
  const importRestaurant = e => {
    let img = {};
    e.keys().forEach((item, index) => {
      img[item.replace("./", "")] = e(item);
    });
    return img;
  };
  const handleNavLoad = arg => {
    setCond({
      ...cond,
      returnNav: arg
    });
  };
  const handleRestaurantLoad = arg => {
    setRestaurantLoad(restaurantLoad = arg);
  }
  const handlePageLoad = arg => {
    setPageLoad(pageLoad = arg);
  }
  const handleFooterLoad = arg => {
    setFooterCase(footerCase = arg);
  }
  useEffect(() => {
    // START MOUNT - IMPORT ASSETS IMAGES
    let imgBrand = importBrand(
      require.context("./../assets/img/brand", false, /\.(png|jpe?g|svg|jpeg)$/)
    );
    let imgIco = importIco(
      require.context(
        "./../assets/img/ico",
        false,
        /\.(png|jpe?g|svg|jpeg)$/
      )
    );
    let imgCommon = importCommon(
      require.context(
        "./../assets/img/common",
        false,
        /\.(png|jpe?g|svg|jpeg)$/
      )
    );
    let imgSample = importSample(
      require.context(
        "./../assets/img/sample",
        false,
        /\.(png|jpe?g|svg|jpeg)$/
      )
    );
    let imgRestaurant = importRestaurant(
      require.context(
        "./../assets/img/restaurant",
        false,
        /\.(png|jpe?g|svg|jpeg)$/
      )
    );
    let imgWhatsOn = importWhatsOn(
      require.context(
        "./../assets/img/whats-on",
        false,
        /\.(png|jpe?g|svg|jpeg)$/
      )
    );
    // let pdfBenedict = importMenuBenedict(
    //   require.context(
    //     "./../assets/menu/benedict",
    //     false,
    //     /\.(pdf)$/
    //   )
    // )
    setCond({
      ...cond,
      brand: imgBrand,
      common: imgCommon,
      sample: imgSample,
      whatson: imgWhatsOn,
      ico: imgIco,
      restaurant: imgRestaurant
    });
  }, []);
  return (
    <PageContext.Provider
      value={{
        ...cond,
        restaurantLoad,
        pageLoad,
        footerCase,
        handleNavLoad: handleNavLoad,
        handleRestaurantLoad: handleRestaurantLoad,
        handlePageLoad: handlePageLoad,
        handleFooterLoad: handleFooterLoad
      }}>
      {children}
    </PageContext.Provider>
  );
};

export default PageContextProvider;
