import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import TagManager from "react-gtm-module";
import { PageContext } from "./../../context/PageContext";

const tagManagerArgs = {
  dataLayer: {
    userId: "007",
    userProject: "Union-Group",
    page: "Privacy Policy",
  },
  dataLayerName: "PageDataLayer",
};
const GET_HOUSE_RULES = gql`
  query GetHouseRules {
    page(id: "house-rules", idType: URI) {
      houseRulesPage {
        section {
          text
          title
        }
      }
    }
  }
`
const Houserules = () => {
  TagManager.dataLayer(tagManagerArgs);
  const { handleNavLoad } = useContext(PageContext);
  const { data } = useQuery(GET_HOUSE_RULES);
  const houseRules = data?.page?.houseRulesPage?.section;
  useEffect(() => {
    handleNavLoad(true);
  }, []);
  return (
    <div className="background-texture-golden py-main">
      <Container className="container-sm">
        <Row>
          {houseRules && houseRules.map((item) => (
            <div className="w-100 mb-5">
              <div className="mb-3 text-center">
                <h5>{item.title}</h5>
              </div>
              <div className="list-primary animated fadeInUp delayp5" dangerouslySetInnerHTML={{ __html: item.text }}>
              </div>
            </div>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Houserules;

export const Test = ({ data }) => {
  return (
    <div>
      test
      <p>{data}</p>
    </div>
  );
};
