import React, { useContext, useEffect, useState, Component } from "react";
import { PageContext } from "./../context/PageContext";
import { Link } from "react-router-dom";

const StickyNavbar = ({ img, alt, className, bookNowRestaurant, linkActive }) => {
  const { brand, common, sample } = useContext(PageContext);
  const images = { brand, common, sample };
  useEffect(_ => {
    const dropdownNavbar = document.querySelector(".dropdown-menu");
    const navbarSticky = document.querySelector(".navbar-sticky");
    const btnBookNow = document.querySelector(".btn-book-now");
    const handleScroll = _ => {
      // console.log(window.pageYOffset);
      if (window.pageYOffset > 465) {
        navbarSticky.classList.add("is-sticky");
        btnBookNow.classList.add("show");
      } else if (window.pageYOffset > 365) {
        // dropdownNavbar.classList.add("close-show");
        navbarSticky.classList.add("change-index");

      } else if (window.pageYOffset < 465) {
        navbarSticky.classList.remove("is-sticky");
        navbarSticky.classList.remove("change-index");
        btnBookNow.classList.remove("show");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return _ => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <div className={"navbar-sticky animated fadeIn delayp5 " + className}>
      <div className="container">
        {images.brand[`${img}`] || img ? (
          <img
            src={images.brand[`${img}`] || img}
            alt={alt}
            className="img-fluid w-100px"
          />
        ) : null}
        <a
          href={bookNowRestaurant?.url}
          target="_blank"
          style={{ visibility: linkActive == "pizza-milano" ? "hidden" : "inherit" }}
        >
          <span class="btn-book-now btn btn-primary">
            <span>{bookNowRestaurant?.text}</span>
          </span>
        </a>
      </div>
    </div>
  );
};

export default StickyNavbar;
