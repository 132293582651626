import React from "react";
import Slider from "react-slick";

export const SectionCover = ({ data }) => {
    const settings = {
        className: "carousel-cover-home",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        lazyLoad: true,
        initialSlide: 0,
    };
    return (
        <Slider {...settings}>
            {data?.length ? data.map((item) => (
                <div>
                    <div
                        className="cover cover-home animated fadeIn"
                        style={{
                            backgroundImage: `url(${item.image.sourceUrl})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "500px",
                            backgroundPositionY: "5px",
                        }}
                    />
                </div>
            )) : null}
        </Slider>
    )
}