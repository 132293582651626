import React, { useContext, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { PageContext } from "./../../context/PageContext";

const NotFound = () => {
    const { ico } = useContext(PageContext);
    useEffect(()=>{
        document.getElementsByClassName("navbar-union")[0].classList.add("d-none");
        document.getElementsByClassName("footer")[0].classList.add("d-none");
    },[]);
    return (
        <>
            <MetaTags>
                <title>Page Not Found (404) - The Union Group</title>
                <meta name="description" content="Page Not Found (404) - The Union Group" />
                <meta property="og:title" content="Page Not Found (404) - The Union Group" />
                <meta property="og:description" content="Page Not Found (404) - The Union Group" />
                <meta property="og:image" content={ico['richlink.jpg']} />
            </MetaTags>
            <section className="section-not-found background-texture-golden">
                <div className="container">
                    <h1>Not Found :(</h1>
                    <p>Sorry, but the page you were trying to view does not exist.</p>
                    <p>It looks like this was the result of either:</p>
                    <ul>
                        <li>a mistyped address</li>
                        <li>an out-of-date link</li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default NotFound
