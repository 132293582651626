import React, { useContext, useEffect, useState } from "react";
import { PageContext } from "./../../context/PageContext";
import { Col, Form } from "react-bootstrap";
import axios from "axios";

import TagManager from "react-gtm-module";
import MetaTags from "react-meta-tags";

const tagManagerArgs = {
  dataLayer: {
    userId: "003",
    userProject: "Union-Group",
    page: "Whatson",
  },
  dataLayerName: "PageDataLayer",
};

const Whatson = () => {
  TagManager.dataLayer(tagManagerArgs);
  const {
    brand,
    common,
    sample,
    ico,
    handleNavLoad,
    handlePageLoad,
    handleFooterLoad,
  } = useContext(PageContext);
  const images = { brand, common, sample, ico };
  let [whatson, setWhatson] = useState([]);
  let [filterBrands, setFilterBrands] = useState([]);
  let [filterType, setFilterType] = useState("all");
  let [displayDescFilter, setDisplayDescFilter] = useState(false);

  useEffect(() => {
    handleNavLoad(true);
    handlePageLoad("whatson");
    // setTimeout(() => {
    //   fetch(`https://cors-proxy.htmldriven.com/?url=https://ap-urea-lss9.antikode.dev/api/whatson`, {
    //     method: "GET",
    //     headers: {
    //          "Content-Type": "application/json",
    //          "Connection": "keep-alive",
    //          "Access-Control-Allow-Origin": "no"
    //     },
    //   })
    //     .then(response => response.json())
    //     .then(data => setWhatson((whatson = data.data)))
    //     .then(handleFooterLoad(false))
    // }, 250);
    setTimeout(() => {
      fetch(
        `https://api.allorigins.win/get?url=` +
          encodeURIComponent(`https://ap-urea-lss9.antikode.dev/api/whatson`)
      )
        .then((response) => response.json())
        .then((data) => setWhatson(JSON.parse(data.contents).data))

        // .then(data => setWhatson((whatson = data.data)))
        .then(handleFooterLoad(false));
    }, 250);
  }, []);

  let [brandValue, setBrand] = useState(0);
  const handleChangeBrand = (e) => {
    setBrand((brandValue = e.target.value));
  };

  const handleChangeType = (e) => {
    setFilterType((filterType = e.target.value));
  };
  const loading =
    whatson.length == 0 && filterBrands.length == 0 ? (
      <div className="loading-state position-relative">
        <img
          src={images.common["Reload-1s-200px.png"]}
          alt="loading"
          className="img-fluid"
        />
      </div>
    ) : (
      <></>
    );
  const brands = [
    {
      brand_name: "THE UNION GROUP",
      brand_id: 0,
    },
    {
      brand_name: "Benedict",
      brand_id: 1,
    },
    {
      brand_name: "Bistecca",
      brand_id: 2,
    },
    {
      brand_name: "Caffè Milano",
      brand_id: 3,
    },
    {
      brand_name: "Cork&Screw",
      brand_id: 4,
    },
    {
      brand_name: "Cork&Screw Country Club",
      brand_id: 5,
    },
    {
      brand_name: "Loewy",
      brand_id: 6,
    },
    {
      brand_name: "Pizza Milano",
      brand_id: 7,
    },
    {
      brand_name: "The Dutch",
      brand_id: 8,
    },
    {
      brand_name: "Union",
      brand_id: 9,
    },
    {
      brand_name: "Roma",
      brand_id: 10,
    },
  ];
  const type = [
    {
      value: "event",
      text: "Event",
    },
    {
      value: "promo",
      text: "Promo",
    },
  ];
  if (whatson.length == 0 && filterBrands.length == 0) {
    handleFooterLoad(false);
  } else if (whatson.length > 0) {
    handleFooterLoad(true);
  } else if (whatson.length > 0 && filterBrands.length == 0) {
    handleFooterLoad(false);
  } else if (whatson.length > 0 || filterBrands.length > 0) {
    handleFooterLoad(true);
  }
  return (
    <>
      <MetaTags>
        <title>The Union Group - What's On</title>
        <meta name="keywords" content="What's On" />
        <meta name="description" content="What's On in The Union Group" />
        <meta property="og:title" content="The Union Group - What's On" />
        <meta
          property="og:description"
          content="What's On in The Union Group"
        />
        <meta property="og:image" content={images.ico["richlink.jpg"]} />
      </MetaTags>
      <div
        className="cover animated fadeIn delayp1"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)), url(${images.common["bg_whats-on.jpg"]})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "300px",
        }}
      >
        <div className="container">
          <h3 className="cover-title cover-title-sm cover-title-whatson animated fadeInUp delayp3">
            What's On <br /> In The Union Group
          </h3>
        </div>
      </div>
      <section className="section-whatson py-main">
        <div className="container container-xs">
          <Form className="mb-3 animated fadeIn">
            <div className="row">
              <div className="col-md-6 col-6">
                <Form.Group>
                  <select
                    className="form-control custom-select"
                    onChange={handleChangeBrand}
                  >
                    <option value={0}>All Brands</option>
                    {brands.map((value, index) => {
                      return (
                        <option key={index} value={`${value.brand_id}`}>
                          {value.brand_name}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
              </div>
              <div className="col-md-6 col-6">
                <Form.Group controlId="formGridCity">
                  <Form.Control
                    as="select"
                    className="custom-select"
                    onChange={handleChangeType}
                  >
                    <option value="all">All Types</option>
                    {type.map((value, index) => {
                      return <option value={value.value}>{value.text}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
          </Form>
        </div>
        <div className="container">
          {whatson.length > 0 ? (
            <div className="row">
              {whatson.map((value, index) => {
                if (value.brand_id == brandValue) {
                  if (filterType == "all") {
                    return (
                      <Col md={4} sm={6} xs={6} key={index} key={index}>
                        <a
                          href={value.url}
                          className="card card-primary animated fadeInUp"
                          target="_blank"
                        >
                          <div className="card-img">
                            <img
                              src={`https://ap-urea-lss9.antikode.dev/storage/${value.image}`}
                              alt="Event Poster"
                              className="img-fluid w-100"
                            />
                          </div>
                          <h5 className="title">{value.brand_name}</h5>
                          <p className="subtitle">{value.date}</p>
                          <h4 className="desc">{value.title}</h4>
                        </a>
                      </Col>
                    );
                  } else if (value.event_type == filterType) {
                    return (
                      <Col md={4} sm={6} xs={6} key={index} key={index}>
                        <a
                          href={value.url}
                          className="card card-primary animated fadeInUp"
                          target="_blank"
                        >
                          <div className="card-img">
                            <img
                              src={`https://ap-urea-lss9.antikode.dev/storage/${value.image}`}
                              alt="Event Poster"
                              className="img-fluid w-100"
                            />
                          </div>
                          <h5 className="title">{value.brand_name}</h5>
                          <p className="subtitle">{value.date}</p>
                          <h4 className="desc">{value.title}</h4>
                        </a>
                      </Col>
                    );
                  }
                } else if (brandValue == 0) {
                  if (filterType == "all") {
                    return (
                      <Col md={4} sm={6} xs={6} key={index} key={index}>
                        <a
                          href={value.url}
                          className="card card-primary animated fadeInUp"
                          target="_blank"
                        >
                          <div className="card-img">
                            <img
                              src={`https://ap-urea-lss9.antikode.dev/storage/${value.image}`}
                              alt="Event Poster"
                              className="img-fluid w-100"
                            />
                          </div>
                          <h5 className="title">{value.brand_name}</h5>
                          <p className="subtitle">{value.date}</p>
                          <h4 className="desc">{value.title}</h4>
                        </a>
                      </Col>
                    );
                  } else if (value.event_type == filterType) {
                    return (
                      <Col md={4} sm={6} xs={6} key={index} key={index}>
                        <a
                          href={value.url}
                          className="card card-primary animated fadeInUp"
                          target="_blank"
                        >
                          <div className="card-img">
                            <img
                              src={`https://ap-urea-lss9.antikode.dev/storage/${value.image}`}
                              alt="Event Poster"
                              className="img-fluid w-100"
                            />
                          </div>
                          <h5 className="title">{value.brand_name}</h5>
                          <p className="subtitle">{value.date}</p>
                          <h4 className="desc">{value.title}</h4>
                        </a>
                      </Col>
                    );
                  }
                } else {
                  return null;
                }
              })}
            </div>
          ) : (
            loading
          )}
        </div>
      </section>
    </>
  );
};

export default Whatson;
