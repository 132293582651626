import { gql, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
// import gsap from "gsap"
import Logo from "./../../assets/img/brand/logo_uniongroup-dark.png";

const GET_TAKEAWAY_MENU = gql`
    query GetMenu {
        page(id: "takeaway-menu", idType: URI) {
            takeawayMenu {
                menu {
                name
                url
                }
            }
        }
    }
`

const TakeAway = () => {
    const { data } = useQuery(GET_TAKEAWAY_MENU);
    const menu = data?.page?.takeawayMenu?.menu;
    // Animation
    useEffect(() => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)

        // prettier-ignore
        // gsap.defaults({ ease: "expo.inOut", duration: 1 })
        // // prettier-ignore
        // gsap
        // .timeline()
        // .to(".loader .brand", 0, { css: { visibility: "visible" }})
        // .from(".loader .brand", { autoAlpha: 0, delay: 1, ease: "none", })
        // .to(".loader .brand", { autoAlpha: 0, }) .to(".loader", { height: 0, })
        // .from(".header-logo-wrapper img", 0.5, { autoAlpha: 0, y: 30, delay: -0.2, ease: "power3.out", })
        // .from(".header-text-wrapper p", 0.5, { autoAlpha: 0, y: 30, delay: -0.4, ease: "power3.out", })
        // .from(".btn-wrapper .btn", 1, { autoAlpha: 0, y: 45, delay: -0.6, ease: "power3.out", stagger: { amount: 0.3, }, })
        // .from("footer p", 0.75, { autoAlpha: 0, y: 20, delay: -0.5, ease: "power3.out", })
        // .from("footer .el-before", 0.75, { autoAlpha: 0, y: 20, delay: -0.5, ease: "power3.out", })

        document.getElementsByClassName("navbar-union")[0].classList.add("d-none");
        document.getElementsByClassName("footer")[0].classList.add("d-none");
    }, [])
    return (
        <div className="bd-menu-landing">
            <div className="wrapper">
                <div className="loader">
                    <img src="" className="brand img-fluid" alt="loader" />
                </div>
                <div className="menu-landing-wrapper union">
                    <section className="menu-landing-header mb-4">
                        <div className="container container-sm">
                            <div className="overflow-hidden header-logo-wrapper mb-4 animated fadeIn delayp1">
                                <img src={Logo} alt="image" className="img-fluid" />
                            </div>
                            <div className="overflow-hidden header-text-wrapper  animated fadeIn delayp2">
                                <p>Stay safe at home and get your favorite dishes by ordering takeaway and delivery from our establishments. Simply click any of these buttons to order!</p>
                            </div>
                        </div>
                    </section>
                    <section className="menu-landing-body">
                        <div className="container container-sm">
                            {
                                menu?.map((value, i) => {
                                    let counter = i + 1;
                                    return (
                                        <div className={`overflow-hidden btn-wrapper animated fadeInUp delayp${counter}`}>
                                            <a
                                                href={value.url}
                                                target="_blank"
                                                className="btn btn-primary"
                                            >
                                                {value.name}
                                                <div className="el-after" />
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>
                    <section>
                        <div className="container container-sm animated fadeIn delayp7">
                            <div>
                                <p className="text-center mb-2">For further enquiries:</p>
                                <div className="text-center">
                                    <div className="pr-2">
                                        <a href="http://wa.me/628111912999" target="_blank" className="text-underline">WhatsApp{" "}</a>
                                    </div>
                                    <div>
                                        |
                                    </div>
                                    <div className="pl-2">
                                        <a href="https://new.uniongroupjakarta.com/" target="_blank" className="text-underline">{" "}Website</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <footer>
                    <span className="el-before" />
                    <div className="container">
                        <div className="overflow-hidden footer-text-wrapper">
                            <p>{new Date().getFullYear()} The Union Group</p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default TakeAway
