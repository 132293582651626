import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";


export const SectionRoom = ({ isVisible, linkActive, data, images }) => {
    const bannerMock = ["", "", ""].map(() => images.common['img_transaparent.png'])
    const [cond, setCond] = useState({
        show: false,
    });
    let [banner, setBanner] = useState(bannerMock);
    const [orderOnline, setOrderOnline] = useState(null);
    let [descBanner, setDescBanner] = useState("");
    let [access_token, setAccess_Token] = useState("");
    let [returnMenu, setReturnMenu] = useState(<div></div>);
    let [modalMenu, setModalMenu] = useState({
        title: "",
        images: "",
        cover: "",
    });
    useEffect(() => {
        setOrderOnline(data?.orderOnlineButton);
        setBanner(data?.bannerImage?.map((item) => item.image.sourceUrl) || bannerMock);
        setDescBanner(data?.middleDescription);
        setModalMenu({
            title: data?.menu?.title,
            images: data?.menu?.brandsMenu?.logo?.sourceUrl,
            cover: data?.menu?.brandsMenu?.cover?.sourceUrl,
        });
        setReturnMenu((returnMenu = <MenuComponent menu={data?.menu?.brandsMenu?.tugMenu} />));
        //Fetch Acces
        const body = JSON.stringify({
            grant_type: "password",
            username: "admin",
            password: "adminpassword",
        });
        fetch("https://mob-integration.uniongroupjakarta.com/oauth/token/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body,
        })
            .then((response) => response.json())
            .then((response) => {
                setAccess_Token((access_token = response.data.detail.access_token));
            })
            .catch((error) => console.log(error));
    }, [data]);

    const handleClick = () => {
        setCond({
            show: !cond.show,
        });
    };
    const NextArrow = ({ onClick }) => {
        return (
            <div className="slick-next-primary" onClick={onClick}>
                <i className="fa fa-chevron-right" />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="slick-prev-primary" onClick={onClick}>
                <i className="fa fa-chevron-left" />
            </div>
        );
    };
    const settings = {
        dots: false,
        infinite: true,
        className: "carousel-restaurant-room",
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerPadding: "60px",
        centerMode: true,
        speed: 500,
        slidesToShow: 1.65,
        slidesToScroll: 1.65,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.65,
                    slidesToScroll: 1.65,
                },
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 528,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const effect = isVisible ? "fadeInUp" : "";
    return (
        <>
            <section className="section-restaurant-gallery">
                <Slider {...settings}>
                    {banner.map((value, index) => {
                        const sumDelay = index + 1;
                        return (
                            <div
                                className={
                                    "carousel-item animated " + effect + " delayp" + sumDelay
                                }
                                key={index}
                            >
                                <img
                                    src={value}
                                    className="img-fluid"
                                    alt="banner-room"
                                />
                            </div>
                        );
                    })}
                </Slider>
                <div className="container container-md">
                    <div className="content">
                        <p
                            className={"text-center animated " + effect + " delayp1"}
                            dangerouslySetInnerHTML={{ __html: descBanner }}
                        >
                        </p>
                        <div className={`btn-placeholder mt-0 mb-0`}>
                            <button
                                className="btn btn-primary btn-initial mb-4 mx-auto"
                                onClick={handleClick}
                            >
                                View Menu
                            </button>
                        </div>
                        {orderOnline?.text && orderOnline?.url && (
                            <div className={`btn-placeholder text-center mt-0`}>
                                <a
                                    href={orderOnline?.url}
                                    target="__blank"
                                    className="btn btn-transparent btn-initial mx-auto"
                                >
                                    {orderOnline?.text}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container">
                    <hr />
                </div>
            </section>
            <Modal
                show={cond.show}
                onHide={handleClick}
                className="modal-menu"
                centered
            >
                <div
                    className="modal-header"
                    style={{
                        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${modalMenu.cover})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "250px",
                    }}
                >
                    <div className="modal-close" onClick={handleClick}>
                        <i class="fal fa-times"></i>
                    </div>
                    <div className="d-flex align-items-center justify-content-center flex-column w-100">
                        <img
                            src={modalMenu.images}
                            alt="logo"
                            className={`w-75px ${linkActive == "pierre" && "w-100px"}`}
                        />
                    </div>
                </div>
                <div className="modal-body background-texture-white">
                    <div className="list-group transparent w-separator">{returnMenu}</div>
                </div>
            </Modal>
        </>
    );
};

const MenuComponent = ({ menu }) => {
    return menu.map((item, index) => (
        <div className="list-group-item">
            <div className="list-group-item-content text-center flex-column">
                <h4>{item?.locations?.locationName || item?.locations?.sectionName}</h4>
                <p className="text-center font-weight-normal text-uppercase">
                    {item?.locations?.locationName && item?.locations?.sectionName}
                </p>
                <div className="btn-placeholder">
                    {item?.locations?.menu?.map((item) => (
                        <a
                            href={item.url || item?.file?.mediaItemUrl}
                            target="_blank"
                            className="btn btn-outline-dark btn-sm mb-3"
                        >
                            <span>{item.title}</span>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    ))
};