import React from "react";
import { Container } from "react-bootstrap";

export const SectionAbout = ({ images, description, headline }) => {
    return (
        <section className="section-about py-main">
            <Container className="container-md">
                {headline ? (
                    <h2>{headline}</h2>
                ) : null}
                <p className="animated fadeInUp delayp1">
                    {description}
                </p>
            </Container>
            <Container className="animated brand-list fadeInUp delayp2">
                <img
                    src={images.common["img_uniongroup_web.png"]}
                    alt="list-resto"
                    className="img-fluid d-none d-md-block"
                />
                <img
                    src={images.common["img_uniongroup_mobile.png"]}
                    alt="list-resto"
                    className="img-fluid d-md-none"
                />
            </Container>
        </section>
    );
};