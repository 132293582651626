import React, { useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap'
import { PageContext } from './../../context/PageContext';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    dataLayer: {
        userId: '006',
        userProject: 'Union-Group',
        page: 'TNC'
    },
    dataLayerName: 'PageDataLayer'
}

const TnC = () => {
    TagManager.dataLayer(tagManagerArgs)
    const { handleNavLoad } = useContext(PageContext);
    useEffect(()=>{
        handleNavLoad(true)
    },[])
    const tnc = {
        membership: [
            "U+REWARDS is The Union Group’s exclusive loyalty program designed for their valued customers and commercial use of the program is prohibited.",
            "U+REWARDS is open for individuals and not available to corporations, charities, partnerships or any other entities.",
            "GoFood, GrabFood and other similar delivery service providers are not authorized to have U+REWARDS account and are not allowed to collect points during their duty time.",
            "The Union Group employees are not allowed to participate in U+REWARDS loyalty program.",
            "Members should always make sure that their information is accurate."
        ],
        bprm: [
            "Benefits will be extended upon activation of membership, which will be different for each membership level.",
            "Membership and its benefits are non-transferable and not valid with other promotions.",
            "The Union Group reserves the right to change or modify membership benefits at any time.",
            "Complimentary birthday treat can only be used once in each outlet and will be given only on the customer’s birthday date.",
            "Birthday week discount can only be used once in each outlet between the birthday date and six days after, and does not apply to tobacco and promotional items.",
            "Please note that priority waitlist does not guarantee a table",
            "The exclusive chance to buy new cakes before they launch is only valid while stocks last",
            "Members will earn U+Points for every transaction and U+Points will expire 1 (one) year from the end of transaction month.",
            "The Union Group reserves the right to change the total points required for redeemable items and the related T&C for rewards at any time.",
            "The Union Group reserves the rights to make an adjustment on the U+REWARDS membership level if target spending is not reached within the given time.",
            "U+REWARDS membership level will be adjusted every January 2nd of each year.",
            "Total spending will be reset every January 2nd of each year.",
            "The Union Group reserves the right to clarify member’s ID at any time.",
            "The Union Group reserves the right to terminate, discontinue or cancel the U+REWARDS membership at any time and in its sole discretion without notice. Once membership terminated, all benefits and privileges shall cease forthwith and any U+Points will be forfeited immediately.",
            "At any time and in The Union Group’s sole discretion (including, but not limited to, where a member was not eligible to earn a specific benefit or points pursuant to these program rules), The Union Group may correct (i) the amount of U+Points or the number of spending credited to a member’s account, and (ii) any other benefit that has been credited to a member’s account, including, but not limited to, any membership level."
        ],
        others: [
            "Under no circumstances shall The Union Group entities, affiliates, any participating property or each of their respective employees be liable for any direct, indirect, incidental, special or consequential damages arising out of the U+REWARDS loyalty program, even if any or all of The Union Group entities have been advised of the possibility of such damages.",
            "Any fraudulent or unauthorized use of the membership is strictly prohibited and may result in termination from the program.",
            "The Union Group maintains the right to amend, change or withdraw the terms & conditions from time to time without prior notice.",
            "By registering, members agree to allow The Union Group to communicate via mail, email, phone and text messages. The Union Group will protect member’s data in accordance with the prevailing laws in Indonesia.",
            "By registering, members are deemed to have read, understood, accepted and are bound by the full terms & conditions of the U+REWARDS loyalty program."
        ]
    }
    return (
        <div className="background-texture-golden py-main">
            <Container className="container-sm">
                <div className="heading">
                    <h3 className="text-uppercase text-center animated fadeInUp delayp1 mb-5">Terms & Condition</h3>
                    <p className="text-gray-400 animated fadeInUp delayp2">
                        These terms and conditions outline the rules and regulations for the use of The Union Group's website and mobile app, U+REWARDS.
                    </p>
                    <p className="text-gray-400  animated fadeInUp delayp2">
                        By accessing this website and/or our mobile app, U+REWARDS, we assume you accept these terms and conditions. Do not continue to use our online services if you do not agree to take all of the terms and conditions stated on this page.
                    </p>
                </div>
                <div className="subheading">
                    <h5 className="text-uppercase mb-3 animated fadeInUp delayp3">Cookies</h5>
                    <p className="text-gray-400 animated fadeInUp delayp4">
                        We employ the use of cookies. By accessing The Union Group, you agreed to use cookies in agreement with the The Union Group's Privacy Policy.
                    </p>
                    <p className="text-gray-400 animated fadeInUp delayp5">
                        Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                    </p>
                </div>
                {/* <ul className="list-primary mb-4 animated fadeInUp delayp3">
                    {tnc.membership.map((value, index)=>{
                        return (
                            <li key={index}>{value}</li>
                        )
                    })}
                </ul> */}
                <div className="subheading">
                    <h5 className="text-uppercase mb-3 animated fadeInUp delayp5">License</h5>
                    <p className="text-gray-400 animated fadeInUp delayp6">
                        Unless otherwise stated, The Union Group and/or its licensors own the intellectual property rights for all material on The Union Group. All intellectual property rights are reserved. You may access this from The Union Group for your own personal use subjected to restrictions set in these terms and conditions.
                    </p>
                    <p className="text-gray-400 animated fadeInUp delayp7">
                        You must not:
                    </p>
                    <ul className="list-primary mb-4 animated fadeInUp delayp7">
                        <li>Republish material from The Union Group</li>
                        <li>Sell, rent or sub-license material from The Union Group</li>
                        <li>Reproduce, duplicate or copy material from The Union Group</li>
                        <li>Redistribute content from The Union Group</li>
                    </ul>
                </div>
                {/* <ul className="list-primary mb-4 animated fadeInUp delayp5">
                    {tnc.bprm.map((value, index)=>{
                        return (
                            <li key={index}>{value}</li>
                        )
                    })}
                </ul> */}
                <div className="subheading">
                    <h5 className="text-uppercase mb-3">Hyperlink to Our Content</h5>
                    <p className="text-gray-400">The following organizations may link to our Website without prior written approval:</p>
                    <ul className="list-primary mb-4">
                        <li>Government agencies;</li>
                        <li>Search engines;</li>
                        <li>News organizations;</li>
                        <li>Online directory distributors may link to our website in the same manner as they hyperlink to the websites of other listed businesses; and</li>
                        <li>System wide accredited businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website.</li>
                    </ul>
                    <p className="text-gray-400">
                        These organizations may link to our home page, to publications or to other website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.
                    </p>
                    <p className="text-gray-400">
                        We may consider and approve other link requests from the following types of organizations:
                    </p>
                    <ul className="list-primary mb-4">
                        <li>commonly-known consumer and/or business information sources;</li>
                        <li>dot.com community sites;</li>
                        <li>associations or other groups representing charities;</li>
                        <li>online directory distributors;</li>
                        <li>internet portals;</li>
                        <li>accounting, law and consulting firms; and</li>
                        <li>educational institutions and trade associations.</li>
                    </ul>
                    <p className="text-gray-400">
                        We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of The Union Group; and (d) the link is in the context of general resource information.
                    </p>
                    <p className="text-gray-400">
                        These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.
                    </p>
                    <p className="text-gray-400">
                        If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to The Union Group. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
                    </p>
                    <p className="text-gray-400">
                        Approved organizations may hyperlink to our Website as follows:
                    </p>
                    <ul className="list-primary mb-4">
                        <li>By use of our corporate name; or</li>
                        <li>By use of the uniform resource locator being linked to; or</li>
                        <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                    </ul>
                    <p className="text-gray-400">
                        No use of The Union Group's logo or other artwork will be allowed for linking absent a trademark license agreement.
                    </p>
                    {/* <ul className="list-primary mb-4">
                        {tnc.others.map((value, index)=>{
                            return (
                                <li key={index}>{value}</li>
                            )
                        })}
                    </ul> */}
                </div>
                <div className="subheading">
                    <h5 className="text-uppercase mb-3">Reservations of rights</h5>
                    <p className="text-gray-400">We reserve the right to request that you remove all links or any particular link to our website. You approve to immediately remove all links to our website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our website, you agree to be bound to and follow these linking terms and conditions.</p>
                </div>
                <div className="subheading">
                    <h5 className="text-uppercase mb-3">Disclaimer</h5>
                    <p className="text-gray-400">To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                    <ul className="list-primary mb-4">
                        <li>limit or exclude our or your liability for death or personal injury;</li>
                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                    </ul>
                    <p className="text-gray-400">
                        The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
                    </p>
                    <p  className="text-gray-400">
                        As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
                    </p>
                </div>
                <div className="subheading">
                    <h5 className="text-uppercase mb-3">U+REWARDS Membership</h5>
                    <ul className="list-primary mb-4">
                        <li>U+REWARDS is The Union Group’s exclusive loyalty program designed for their valued customers and commercial use of the program is prohibited.</li>
                        <li>U+REWARDS is open for individuals and not available to corporations, charities, partnerships or any other entities.</li>
                        <li>GoFood, GrabFood and other similar delivery service providers are not authorized to have U+REWARDS account and are not allowed to collect points during their duty time.</li>
                        <li>The Union Group employees are not allowed to participate in U+REWARDS loyalty program.</li>
                        <li>Members should always make sure that their information is accurate.</li>
                    </ul>
                </div>
                <div className="subheading">
                    <h5 className="text-uppercase mb-3">U+REWARDS Benfits, Points, Rewards & Membership Level</h5>
                    <ul className="list-primary mb-4">
                       <li>Benefits will be extended upon activation of membership, which will be different for each membership level.</li>
                       <li>Membership and its benefits are non-transferable and not valid with other promotions.</li>
                       <li>The Union Group reserves the right to change or modify membership benefits at any time.</li>
                       <li>Complimentary birthday treat can only be used once in each outlet and will be given only on the customer’s birthday date.</li>
                       <li>Birthday week discount can only be used once in each outlet between the birthday date and six days after, and does not apply to tobacco and promotional items.</li>
                       <li>Please note that priority waitlist does not guarantee a table</li>
                       <li>The exclusive chance to buy new cakes before they launch is only valid while stocks last</li>
                       <li>Members will earn U+Points for every transaction and U+Points will expire 1 (one) year from the end of transaction month.</li>
                       <li>The Union Group reserves the right to change the total points required for redeemable items and the related T&C for rewards at any time.</li>
                       <li>The Union Group reserves the rights to make an adjustment on the U+REWARDS membership level if target spending is not reached within the given time.</li>
                       <li>U+REWARDS membership level will be adjusted every January 2nd of each year.</li>
                       <li>Total spending will be reset every January 2nd of each year.</li>
                       <li>The Union Group reserves the right to clarify member’s ID at any time.</li>
                       <li>The Union Group reserves the right to terminate, discontinue or cancel the U+REWARDS membership at any time and in its sole discretion without notice. Once membership terminated, all benefits and privileges shall cease forthwith and any U+Points will be forfeited immediately.</li>
                       <li>At any time and in The Union Group’s sole discretion (including, but not limited to, where a member was not eligible to earn a specific benefit or points pursuant to these program rules), The Union Group may correct (i) the amount of U+Points or the number of spending credited to a member’s account, and (ii) any other benefit that has been credited to a member’s account, including, but not limited to, any membership level.</li>
                    </ul>
                </div>
                <div className="subheading">
                    <h5 className="text-uppercase mb-3">Others</h5>
                    <ul className="list-primary mb-4">
                        <li>Under no circumstances shall The Union Group entities, affiliates, any participating property or each of their respective employees be liable for any direct, indirect, incidental, special or consequential damages arising out of the U+REWARDS loyalty program, even if any or all of The Union Group entities have been advised of the possibility of such damages.</li>
                        <li>Any fraudulent or unauthorized use of the membership is strictly prohibited and may result in termination from the program.</li>
                        <li>By registering, members agree to allow The Union Group to communicate via mail, email, phone and text messages. The Union Group will protect member’s data in accordance with the prevailing laws in Indonesia.</li>
                        <li>By registering, members are deemed to have read, understood, accepted and are bound by the full terms & conditions of the U+REWARDS loyalty program.</li>
                    </ul>
                </div>
            </Container>
        </div>
    )
}

export default TnC;