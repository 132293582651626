import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";

export const SectionRoom = ({ images, windowWidth, data }) => {
    let [width, setWidth] = useState(window.innerWidth);
    let [height, setHeight] = useState(window.innerHeight);

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    }, []);
    const [cond, setCond] = useState({
        detailRoom: "",
        value: "",
    });
    useEffect(() => {
        setCond({
            detailRoom: data[0]?.description,
            value: data[0]?.name,
        })
    }, [data])
    const bannerRoom = data.map((item, index) => ({
        title: item.image.title,
        imgSrc: width < 781 ? item.image.sourceUrl : data[index - 1 >= 0 ? index - 1 : data.length - 1].image.sourceUrl
    }))
    let [valueDefault, setValue] = useState(1);
    const tabClick = (e) => {
        setValue((valueDefault = e));
    };
    const nextClick = (e) => {
        setValue((valueDefault = valueDefault + 1));
        if (valueDefault == 6) {
            setValue((valueDefault = 1));
        }
    };
    const prevClick = (e) => {
        setValue((valueDefault = valueDefault - 1));
        if (valueDefault == 0) {
            setValue((valueDefault = 5));
        }
    };
    let [dropdownRoom, setDropdownRoom] = useState(false);
    const dropdownClick = () => {
        setDropdownRoom((dropdownRoom = !dropdownRoom));
    };
    let [condSwipe, setCondSwipe] = useState({
        activeSlide: 0,
    });
    const settings = {
        dots: true,
        infinite: true,
        className: "center carousel-restaurant-room",
        nextArrow: <NextArrow nextClick={nextClick} />,
        prevArrow: <PrevArrow prevClick={prevClick} />,
        centerMode: true,
        speed: 500,
        slidesToShow: 1.65,
        slidesToScroll: 1.65,
        afterChange: (current) => setCondSwipe({ activeSlide: current }),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.65,
                    slidesToScroll: 1.65,
                },
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 528,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
        appendDots: (dots) => (
            <div>
                <Container className="">
                    <div>
                        <ul className={dropdownRoom ? "list-banner open" : "list-banner"}>
                            {dots}
                        </ul>
                    </div>
                </Container>
                <Container>
                    <div onClick={dropdownClick} className="d-md-none">
                        <p className="dropdown-room">
                            {data.filter((item, index) => index === condSwipe.activeSlide)[0]?.name}
                        </p>
                    </div>
                </Container>
            </div>
        ),
        customPaging: (i) => (
            <div className="tab" data-attr={i + 1}>
                <p
                    onClick={() => {
                        {
                            setCond({ detailRoom: data[i].description, value: data[i]?.name });
                            tabClick(i + 1);
                            dropdownClick(false);
                        }
                    }}
                >
                    {data[i]?.name}
                </p>
            </div>
        ),
    };
    return (
        <div className="section-gallery restaurant-room">
            <Slider {...settings}>
                {bannerRoom.map((value, index) => {
                    return (
                        <div className="carousel-item" key={index} data-view={value.title}>
                            <img
                                src={value.imgSrc}
                                className="img-fluid"
                                alt="banner-room"
                                data-room={value.title}
                            />
                        </div>
                    );
                })}
            </Slider>
            <Container className="mt-4 container-md">
                <div
                    className="room-tab-detail"
                    dangerouslySetInnerHTML={{
                        __html: data.filter((item, index) => index === condSwipe.activeSlide)[0]?.description
                    }}
                >
                </div>
            </Container>
        </div>
    );
};

const NextArrow = ({ detailRoom, nextClick, onClick }) => {
    return (
      <div
        className="slick-next-primary"
        onClick={() => {
          onClick();
          nextClick();
        }}
      >
        <i className="fa fa-chevron-right" />
      </div>
    );
  };
  
  const PrevArrow = ({ secondClick, prevClick, onClick }) => {
    return (
      <div
        className="slick-prev-primary"
        onClick={() => {
          onClick();
          prevClick();
        }}
      >
        <i className="fa fa-chevron-left" />
      </div>
    );
  };