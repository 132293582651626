import L from "leaflet";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Slider from "react-slick";

export const SectionLocation = ({
  locationTitle,
  isVisible,
  linkActive,
  location,
}) => {
  let [locationTest, setLocation] = useState({
    lat: -6.230032491437649,
    lng: 106.81871841195974,
  });
  const settings = {
    carousel2: {
      className: "carousel-two-location",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 720,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
    carousel3: {
      className: "carousel-more-location",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            className: "carousel-more-location view-2",
            dots: true,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 320,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  };
  const effect = isVisible ? "fadeInUp" : "";
  const myIcon = L.icon({
    iconUrl: require("./../../assets/img/common/ic_location_pin.png"),
    iconSize: [20, 32],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
  });
  let locationReturn;
  useEffect(() => {
    if (location.length) {
      setLocation({
        lat: location[0]?.mapsLocation?.latitude,
        lng: location[0]?.mapsLocation?.longitude,
      });
    }
  }, [location]);
  if (location.length == 1) {
    locationReturn = (
      <Row>
        <Col md={7} sm={12}>
          <Map
            center={locationTest}
            zoom={15}
            style={{ marginBottom: "1.5rem" }}
            scrollWheelZoom={false}
            className="restaurant-map"
          >
            <div className="bg-map-gray"></div>
            <TileLayer
              zoom={17}
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
              id="mapbox.streets"
              accessToken="pk.eyJ1Ijoic25kYW50aWtvZGUiLCJhIjoiY2p6cnZ0NTcxMWM1YzNtbnBlZmJ2NzlkcyJ9.dZd20RU0nnsrWS7yprgGyw"
            />
            <Marker draggable position={locationTest} icon={myIcon}>
              <Popup>Marker</Popup>
            </Marker>
          </Map>
        </Col>
        <Col md={5} sm={12}>
          {location.map((value, index) => {
            return (
              <div className={"location-item animated " + effect + " delayp1"}>
                <div className="location-address">
                  <h5>{value.place}</h5>
                  <p dangerouslySetInnerHTML={{ __html: value.address }}></p>
                  <a
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-link"
                  >
                    Map & Direction
                  </a>
                </div>
                <div className="location-hours">
                  <h5>Opening Hours</h5>
                  <p dangerouslySetInnerHTML={{ __html: value?.openHours }}></p>
                </div>
                <div className="location-contact">
                  <h5>Contact Us</h5>
                  <p>Bookings & enquiries</p>
                  {value?.contact ? (
                    <p>
                      PH:{" "}
                      <a
                        href={`tel: ${value.contact}`}
                        className="btn btn-link"
                      >
                        {value.contact}
                      </a>
                    </p>
                  ) : null}
                  {value?.contactWa ? (
                    <p>
                      WA:{" "}
                      <a
                        href={`https://wa.me/${value.contactWa
                          .split(" ")
                          .join("")}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-link"
                      >
                        +{value.contactWa}
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>
            );
          })}
        </Col>
      </Row>
    );
  } else if (location.length == 2) {
    locationReturn = (
      <Slider {...settings.carousel2}>
        {location.map((value, index) => {
          return (
            <div className="carousel-item">
              <div className="location-item view-two">
                <div className="location-address">
                  <h5>{value.place}</h5>
                  <p dangerouslySetInnerHTML={{ __html: value.address }}></p>
                  <a
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-link"
                  >
                    Map & Direction
                  </a>
                </div>
                <div className="location-hours">
                  <h5>Opening Hours</h5>
                  <p dangerouslySetInnerHTML={{ __html: value?.openHours }}></p>
                </div>
                <div className="location-contact">
                  <h5>Contact Us</h5>
                  <p>Bookings & enquiries</p>
                  {value?.contact ? (
                    <p>
                      PH:{" "}
                      <a
                        href={`tel: ${value.contact}`}
                        className="btn btn-link"
                      >
                        {value.contact}
                      </a>
                    </p>
                  ) : null}
                  {value?.contactWa ? (
                    <p>
                      WA:{" "}
                      <a
                        href={`https://wa.me/${value.contactWa
                          .split(" ")
                          .join("")}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-link"
                      >
                        +{value.contactWa}
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  } else if (location.length > 2) {
    locationReturn = (
      <Slider {...settings.carousel3}>
        {location.map((value, index) => {
          return (
            <div className={"carousel-item " + value.classCarousel}>
              <div className="location-item view-more">
                <div className="location-address">
                  <h5>{value.place}</h5>
                  <p dangerouslySetInnerHTML={{ __html: value.address }}></p>
                  <a
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-link"
                  >
                    Map & Direction
                  </a>
                </div>
                <div className="location-hours">
                  <h5>Opening Hours</h5>
                  <p dangerouslySetInnerHTML={{ __html: value?.openHours }}></p>
                </div>
                <div className="location-contact">
                  <h5>Contact Us</h5>
                  <p>Bookings & enquiries</p>
                  {value?.contact ? (
                    <p>
                      PH:{" "}
                      <a
                        href={`tel: ${value.contact}`}
                        className="btn btn-link"
                      >
                        {value.contact}
                      </a>
                    </p>
                  ) : null}
                  {value?.contactWa ? (
                    <p>
                      WA:{" "}
                      <a
                        href={`https://wa.me/${value.contactWa
                          .split(" ")
                          .join("")}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-link"
                      >
                        +{value.contactWa}
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  }
  return (
    <>
      <section
        className={
          "section-about py-main animated fadeInUp delayp4 " + linkActive
        }
      >
        <div
          className="container container-md"
          dangerouslySetInnerHTML={{ __html: locationTitle }}
        ></div>
      </section>
      <section className={"section-location py-main pt-0 " + linkActive}>
        <div className="container mb-3">{locationReturn}</div>
      </section>
    </>
  );
};
