import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { PageContext } from "./../../context/PageContext";
import TrackVisibility from "react-on-screen";
import { Parallax } from "react-parallax";
import TagManager from 'react-gtm-module'
import MetaTags from 'react-meta-tags';

const tagManagerArgs = {
  dataLayer: {
      userId: '004',
      userProject: 'Union-Group',
      page: 'U+Rewards'
  },
  dataLayerName: 'PageDataLayer'
}

const Rewards = () => {
  TagManager.dataLayer(tagManagerArgs)
  const { brand, common, sample, ico, restaurant, handleNavLoad, handlePageLoad } = useContext(PageContext);
  const images = { brand, common, sample, restaurant, ico };
  useEffect(() => {
    handleNavLoad(true);
    handlePageLoad("rewards");
  }, []);
  return (
    <>
      <MetaTags>
          <title>The Union Group - Loyalty</title>
          <meta name="keywords" content="Rewards, Loyalti" />
          <meta name="description" content="U+REWARDS is The Union Group’s new and exclusive loyalty program. Join to start earning points and get exclusive privileges at all The Union Group establishments." />
          <meta property="og:title" content="The Union Group - Loyalty" />
          <meta property="og:description" content="U+REWARDS is The Union Group’s new and exclusive loyalty program. Join to start earning points and get exclusive privileges at all The Union Group establishments." />
          <meta property="og:image" content={images.ico['richlink.jpg']} />
      </MetaTags>
      <Parallax
        blur={0}
        bgImage={require("../../assets/img/common/img_banner_download_app.jpeg")}
        bgImageAlt="BG Download App"
        strength={200}
        className="section-app"
      >
        <Container style={{ paddingTop: "4rem" }}>
          <Row>
            <Col md={7} className="content-center cover-rewards-text">
              <div>
                <h3 className="title text-uppercase animated fadeInUp delayp1">
                  What is U+Rewards?
                </h3>
                <p className="text-gray-100 animated fadeInUp delayp2">
                U+Rewards is The Union Group’s exclusive loyalty program. Join to start earning points and get exclusive privileges at all The Union Group establishments.
                </p>
                <div className="mt-4 download-placeholder animated fadeInUp delayp3">
                  <a
                    href="https://apps.apple.com/us/app/u-rewards/id1445510338"
                    target="_blank"
                    className="mr-3"
                  >
                    <img
                      src={images.common["button_appstore.png"]}
                      alt="apps-store"
                      className="img-fluid"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.uniongroup.union"
                    target="_blank"
                    className="mr-3"
                  >
                    <img
                      src={images.common["button_playstore.png"]}
                      alt="play-store"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={5} className="order-lg-first order-md-first cover-rewards-image mt-4">
              <img
                src={images.common["img_device.png"]}
                alt="phone"
                className="img-fluid img-device animated fadeInUp"
              />
            </Col>
          </Row>
        </Container>
      </Parallax>
      <SectionWhatsOnInApp images={images} />
      <TrackVisibility>
        <SectionBenefits images={images} />
      </TrackVisibility>
    </>
  );
};

export default Rewards;

const SectionWhatsOnInApp = ({ images }) => {
  return (
    <section className="py-main section-about bg-gray-50">
      <Container className="container-sm">
          <div className="heading text-center">
            <h3 className="text-uppercase  animated fadeInUp delayp1"> What's in The App</h3>
          </div>
      </Container>
      <Container className="container-sm">
        <Row>
          <Col md={6} className="colom-separator">
            <Row>
              <Col md={12}>
                <div className="list-group transparent pr-2 pl-4">
                  <div className="list-group-item">
                    <img
                      src={images.common[`ic_promo.png`]}
                      alt="earn-points"
                      className="img-fluid"
                    />
                    <div className="list-group-item-content">
                      <h4>Earn Points</h4>
                      <p>Book a table instantly and earn U+Points when you dine</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="list-group transparent pr-2 pl-4">
                  <div className="list-group-item">
                    <img
                      src={images.common[`ic_reward.png`]}
                      alt="earn-points"
                      className="img-fluid"
                    />
                    <div className="list-group-item-content">
                      <h4>Redeem Rewards</h4>
                      <p>Redeem points for amazing rewards</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <div className="list-group transparent pr-2 pl-4">
                  <div className="list-group-item">
                    <img
                      src={images.common[`ic_account.png`]}
                      alt="earn-points"
                      className="img-fluid"
                    />
                    <div className="list-group-item-content">
                      <h4>Membership Level</h4>
                      <p>Keep track of your membership level, benefits, points and transactions</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="list-group transparent pr-2 pl-4">
                  <div className="list-group-item">
                    <img
                      src={images.common[`ic_challenge.png`]}
                      alt="earn-points"
                      className="img-fluid"
                    />
                    <div className="list-group-item-content">
                      <h4>Challenge</h4>
                      <p>Complete exciting challenges and earn bonus points</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const SectionBenefits = ({ images }) => {
  const [cond, setCond] = useState({
    active: "level",
    tier: "silver"
  });
  const activeTab = arg => {
    setCond({
      ...cond,
      active: arg
    });
  };
  const activeTier = arg => {
    switch (arg) {
      case "silver":
        setCond({
          active: "level",
          tier: "silver"
        });
        break;

      case "golden":
        setCond({
          active: "level",
          tier: "golden"
        });
        break;

      case "special":
        setCond({
          active: "level",
          tier: "special"
        });
        break;

      default:
        return null;
    }
  };
  const activeLevel = cond.active === "level" ? "active" : "";
  const activeBrand = cond.active === "brand" ? "active" : "";
  const activeSilver = cond.tier === "silver" ? "active" : "";
  const activeGolden = cond.tier === "golden" ? "active" : "";
  const activeSpecial = cond.tier === "special" ? "active" : "";
  if (cond.active == "level") {
    return (
      <section className="rewards-tier py-main">
        <Container className="container-md">
          <div className="heading text-center">
            <h3 className="text-uppercase">Member Benefits</h3>
          </div>
          <Row className="box-tier">
            <div className="col-md-4 col-4 content-center-y">
              <img
                src={images.brand["ic_badge_silver.png"]}
                alt="silver"
                className={"icon-tier " + activeSilver}
                onClick={() => activeTier("silver")}
              />
            </div>
            <div className="col-md-4 col-4 content-center-y">
              <img
                src={images.brand["ic_badge_gold.png"]}
                alt="silver"
                className={"icon-tier " + activeGolden}
                onClick={() => activeTier("golden")}
              />
            </div>
            <div className="col-md-4 col-4 content-center-y">
              <img
                src={images.brand["ic_badge_black.png"]}
                alt="silver"
                className={"icon-tier " + activeSpecial}
                onClick={() => activeTier("special")}
              />
            </div>
          </Row>
          <Row>
            <Col md={6} className="mx-auto">
              <div className="tab-rewards">
                <div className={"tab " + activeLevel} onClick={() => activeTab("level")}>
                  <p>
                    By Level
                  </p>
                </div>
                <div className={"tab " + activeBrand} onClick={() => activeTab("brand")}>
                  <p>
                    By Brand
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Container>
            <Row>
              <ReturnLevel images={images} tier={cond.tier} />
            </Row>
          </Container>
        </Container>
      </section>
    );
  } else if (cond.active == "brand") {
    return (
      <section className="rewards-tier py-main">
        <Container className="container-md">
          <div className="heading text-center">
            <h4>Member Benefits</h4>
          </div>
          <Row className="box-tier">
            <div className="col-md-4 col-4 content-center-y">
              <img
                src={images.brand["ic_badge_silver.png"]}
                alt="silver"
                className={"icon-tier " + activeSilver}
                onClick={() => activeTier("silver")}
              />
            </div>
            <div className="col-md-4 col-4 content-center-y">
              <img
                src={images.brand["ic_badge_gold.png"]}
                alt="silver"
                className={"icon-tier " + activeGolden}
                onClick={() => activeTier("golden")}
              />
            </div>
            <div className="col-md-4 col-4 content-center-y">
              <img
                src={images.brand["ic_badge_black.png"]}
                alt="silver"
                className={"icon-tier " + activeSpecial}
                onClick={() => activeTier("special")}
              />
            </div>
          </Row>
          <Row>
            <Col md={6} className="mx-auto">
              <div className="tab-rewards">
                <div className={"tab " + activeLevel} onClick={() => activeTab("level")}>
                  <p>
                    By Level
                  </p>
                </div>
                <div className={"tab " + activeBrand} onClick={() => activeTab("brand")}>
                  <p>
                    By Brand
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Container>
            <Row>
              <ReturnBrand images={images} tier={cond.tier}  />
            </Row>
          </Container>
        </Container>
      </section>
    );
  }
};

const ReturnLevel = ({ images, tier }) => {
  const [cond, setCond] = useState({
    show: false,
    imgInModal: "",
    titleInModal: "",
    dataBirthdayTreat: []
  });
  const level = [
    {
      title: "silver",
      img: "icon_benefit_points.png",
      caption: "1.00x",
      desc: "Point multiplier per eligible IDR spent",
      modalArg: "",
      addClass: ""
    },
    {
      title: "silver",
      img: "ic_benefit_birthday.png",
      caption: "",
      desc: "Birthday treats",
      modalArg: "birthday",
      addClass: "w-arrow"
    },
    {
      title: "silver",
      img: "ic_benefit_gift.png",
      caption: "",
      desc: "Discount on your total bills on your birthday week",
      modalArg: "gift",
      addClass: "w-arrow"
    },
    {
      title: "silver",
      img: "ic_benefit_bonus.png",
      caption: "",
      desc: "Welcome bonus points",
      modalArg: "bonus",
      addClass: "w-arrow"
    },
    {
      title: "golden",
      img: "icon_benefit_points.png",
      caption: "1.50x",
      desc: "Point multiplier per eligible IDR spent",
      modalArg: "",
      addClass: ""
    },
    {
      title: "golden",
      img: "ic_benefit_birthday.png",
      caption: "",
      desc: "Birthday treats",
      modalArg: "birthday",
      addClass: "w-arrow"
    },
    {
      title: "golden",
      img: "ic_benefit_gift.png",
      caption: "",
      desc: "Discount on your total bills on your birthday week",
      modalArg: "gift",
      addClass: "w-arrow"
    },
    {
      title: "golden",
      img: "icon_benefit_points.png",
      caption: "",
      desc: "Upgrade bonus points",
      modalArg: "upgradeBonus",
      addClass: "w-arrow"
    },
    {
      title: "golden",
      img: "ic_benefit_priority.png",
      caption: "",
      desc: "Priority waitlist",
      modalArg: "priorityWaitlist",
      addClass: "w-arrow"
    },
    {
      title: "golden",
      img: "ic_exclusive_preview.jpg",
      caption: "",
      desc: "Exclusive preview of new cake",
      modalArg: "exclusivePreviewNewCake",
      addClass: "w-arrow"
    },
    {
      title: "special",
      img: "icon_benefit_points.png",
      caption: "2.00x",
      desc: "Point multiplier per eligible IDR spent",
      modalArg: "",
      addClass: ""
    },
    {
      title: "special",
      img: "ic_benefit_birthday.png",
      caption: "",
      desc: "Birthday treats",
      modalArg: "birthday",
      addClass: "w-arrow"
    },
    {
      title: "special",
      img: "ic_benefit_gift.png",
      caption: "",
      desc: "Discount on your total bills on your birthday week",
      modalArg: "gift",
      addClass: "w-arrow"
    },
    {
      title: "special",
      img: "icon_benefit_points.png",
      caption: "",
      desc: "Upgrade bonus points",
      modalArg: "upgradeBonus",
      addClass: "w-arrow"
    },
    {
      title: "special",
      img: "ic_benefit_priority.png",
      caption: "",
      desc: "Priority waitlist",
      modalArg: "priorityWaitlist",
      addClass: "w-arrow"
    },
    {
      title: "special",
      img: "ic_benefit_vip_table.png",
      caption: "",
      desc: "Priority RSVP",
      modalArg: "priorityRSVP",
      addClass: "w-arrow"
    },
    {
      title: "special",
      img: "ic_benefit_dish.png",
      caption: "",
      desc: "Exclusive preview of seasonal off-menu dish",
      modalArg: "exclusivePreviewMenuDish",
      addClass: "w-arrow"
    },
    {
      title: "special",
      img: "ic_exclusive_preview.jpg",
      caption: "",
      desc: "Exclusive preview of new cake",
      modalArg: "exclusivePreviewNewCake",
      addClass: "w-arrow"
    },
    {
      title: "special",
      img: "ic_benefit_appreciation.png",
      caption: "",
      desc: "Member appreciation night",
      modalArg: "memberAppreciationNight",
      addClass: "w-arrow"
    }
  ];
  const dataModal = {
    birthdayTreats: [
      {
        img: "union-square.png",
        title: "Union",
        content: "Old Fashioned Chocolate Cake",
        footer: "Claim at any UNION outlets on your birthday"
      },
      {
        img: "benedict-square.png",
        title: "Benedict",
        content: "Cookies & Cream Cake",
        footer: "Claim at any BENEDICT outlets on your birthday"
      },
      {
        img: "loewy-square.png",
        title: "Loewy",
        content: "Melted Triple Chocolate Cake",
        footer: "Claim at LOEWY on your birthday"
      },
      {
        img: "corknscrew-square.png",
        title: "Cork&Screw",
        content: "Nutella Cake",
        footer: "Claim at any CORK&SCREW outlets on your birthday"
      },
      {
        img: "pizza-milano-square.png",
        title: "Pizza Milano",
        content: "Margharita Pizza",
        footer: "Claim at PIZZA MILANO on your birthday"
      },
      {
        img: "corknscrewcc-square.png",
        title: "Cork&Screw Country Club",
        content: "Trio Molten Cake",
        footer: "Claim at CORK&SCREW COUNTRY CLUB on your birthday"
      },
      {
        img: "the-dutch-square.png",
        title: "The Dutch",
        content: "Chocolate Molten Cake",
        footer: "Claim at THE DUTCH on your birthday"
      },
      {
        img: "caffe-millano-square.png",
        title: "Caffe Millano",
        content: "Warm Melted Chocolate Cake",
        footer: "Claim at CAFFE MILLANO on your birthday"
      },
      {
        img: "bistecca-square.png",
        title: "Bistecca",
        content: "Signature Baked Alaska",
        footer: "Claim at BISTECCA on your birthday"
      }
    ],
    gift: [
      {
        img: "bistecca-square.png",
        title: "Bistecca",
        content: "5% OFF",
        footer: "Claim this benefit on your birthday week at BISTECCA"
      },
      {
        img: "corknscrewcc-square.png",
        title: "Cork&Screw Country Club",
        content: "5% OFF",
        footer:
          "Claim this benefit on your birthday week at CORK&SCREW COUNTRY CLUB"
      },
      {
        img: "union-square.png",
        title: "Union",
        content: "5% OFF",
        footer: "Claim this benefit on your birthday week at any UNION outlets"
      },
      {
        img: "benedict-square.png",
        title: "Benedict",
        content: "5% OFF",
        footer:
          "Claim this benefit on your birthday week at any BENEDICT outlets"
      },
      {
        img: "loewy-square.png",
        title: "Loewy",
        content: "5% OFF",
        footer: "Claim this benefit on your birthday week at LOEWY"
      },
      {
        img: "the-dutch-square.png",
        title: "The Dutch",
        content: "5% OFF",
        footer: "Claim this benefit on your birthday week at THE DUTCH"
      },
      {
        img: "corknscrew-square.png",
        title: "Cork&Screw",
        content: "5% OFF",
        footer:
          "Claim this benefit on your birthday week at any CORK&SCREW outlets"
      },
      {
        img: "pizza-milano-square.png",
        title: "Pizza Milano",
        content: "5% OFF",
        footer: "Claim this benefit on your birthday week at PIZZA MILANO"
      },
      {
        img: "caffe-millano-square.png",
        title: "Caffe Millano",
        content: "5% OFF",
        footer: "Claim this benefit on your birthday week at CAFFE MILLANO"
      }
    ],
    bonus: [
      {
        img: "",
        title: "Earn 500 U+Points when you sign up"
      }
    ],
    upgradeBonus: [
      {
        img: "",
        title: "Earn 1,000 U+Points when you level-up to Gold level"
      }
    ],
    priorityWaitlist: [
      {
        img: "",
        title: "Priority Waitlist"
      }
    ],
    exclusivePreviewNewCake: [
      {
        img: "",
        title: "An exclusive privilege to buy a new cake one day before it launches"
      }
    ],
    priorityRSVP: [
      {
        img: "",
        title: "Priority RSVP on VIP tables during events"
      }
    ],
    exclusivePreviewMenuDish: [
      {
        img: "",
        title: "Exclusive preview of seasonal off-menu dish at BISTECCA, CAFFÈ MILANO, and CORK&SCREW COUNTRY CLUB"
      }
    ],
    memberAppreciationNight: [
      {
        img: "",
        title: "Member appreciation night"
      }
    ]
  };
  const modal = arg => {
    switch (arg) {
      case "birthday":
        setCond({
          show: !cond.show,
          imgModal: "ic_benefit_birthday.png",
          titleInModal: "Birthday treats",
          dataBirthdayTreat: dataModal.birthdayTreats
        });
        break;

      case "gift":
        setCond({
          show: !cond.show,
          imgModal: "ic_benefit_gift.png",
          titleInModal: "Discount on your total bills on your birthday week",
          dataBirthdayTreat: dataModal.gift
        });
        break;

      case "bonus":
        setCond({
          show: !cond.show,
          imgModal: "ic_benefit_bonus.png",
          titleInModal: "Welcome bonus points",
          dataBirthdayTreat: dataModal.bonus
        });
        break;

        case "upgradeBonus":
          setCond({
            show: !cond.show,
            imgModal: "ic_benefit_points.png",
            titleInModal: "Upgrade bonus points",
            dataBirthdayTreat: dataModal.upgradeBonus
          });
        break;

        case "priorityWaitlist":
          setCond({
            show: !cond.show,
            imgModal: "ic_benefit_priority.png",
            titleInModal: "Priority Wailist",
            dataBirthdayTreat: dataModal.priorityWaitlist
          });
        break;

        case "exclusivePreviewNewCake":
          setCond({
            show: !cond.show,
            imgModal: "ic_exclusive_preview.jpg",
            titleInModal: "Exclusive Preview of New Cake",
            dataBirthdayTreat: dataModal.exclusivePreviewNewCake
          });
        break;

        case "priorityRSVP":
          setCond({
            show: !cond.show,
            imgModal: "ic_benefit_vip_table.png",
            titleInModal: "Priority RSVP",
            dataBirthdayTreat: dataModal.priorityRSVP
          });
        break;

        case "exclusivePreviewMenuDish":
          setCond({
            show: !cond.show,
            imgModal: "ic_benefit_dish.png",
            titleInModal: "Exclusive Preview of Seasonal OFF-Menu Dish",
            dataBirthdayTreat: dataModal.exclusivePreviewMenuDish
          });
        break;

        case "memberAppreciationNight":
          setCond({
            show: !cond.show,
            imgModal: "ic_benefit_appreciation.png",
            titleInModal: "Member Appreciation Night",
            dataBirthdayTreat: dataModal.memberAppreciationNight
          });
        break;

      case "close":
        setCond({
          show: false,
          dataBirthdayTreat: []
        });
        break;

      default:
        return null;
    }
  };
  return (
    <>
      {level.map((value, index) => {
        const sumDelay = index + 1;
        if (value.title == tier) {
          return (
            <div
              key={index}
              className={"col-lg-3 col-md-6 col-12 mb-3 animated fadeInUp delayp1"}
            >
              <div
                className={
                  "list-group list-group-level " + value.addClass
                }
                onClick={
                  value.addClass === "w-arrow"
                    ? () => modal(`${value.modalArg}`)
                    : ""
                }
              >
                <div className="list-group-item">
                  <img
                    src={images.brand[`${value.img}`]}
                    alt="benefit points"
                    className="img-fluid"
                  />
                  <div className="list-group-item-content">
                    <p className="caption">{value.caption}</p>
                    <p>{value.desc}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
      <Modal show={cond.show} onHide={() => modal("close")} className="modal-rewards">
        <div className="modal-header dark">
          <img
          
            src={images.brand[`${cond.imgModal}`]}
            alt="icon_benefit_points"
            className="w-75px"
          />
          <p>{cond.titleInModal}</p>
          <button className="btn btn-transparent" onClick={() => modal("close")}>
            <span><i class="fal fa-times"></i></span>
          </button>
        </div>
        <div className="modal-body">
          <div className="list-group list-group-level transparent">
            {cond.dataBirthdayTreat.length > 0
              ? cond.dataBirthdayTreat.map((value, index) => {
                  const sumDelay = index + 1;
                  return (
                    <div
                      className={value.img == "" || value.img == undefined ? "list-group-item text-center animated fadeInUp delayp" + sumDelay :  "list-group-item animated fadeInUp delayp" + sumDelay}
                    >
                      {value.img == "" || value.img == undefined ?  
                        <></>
                        :
                        <img src={images.brand[`${value.img}`]} className="img-fluid wx-100px"/>
                      }
                      <div className="list-group-item-content pt-0">
                        <small className="help-blok text-uppercase">
                          {value.title}
                        </small>
                        <h4>{value.content}</h4>
                        <p>{value.footer}</p>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </Modal>
    </>
  );
};

const ReturnBrand = ({ images, tier }) => {
  const [cond, setCond] = useState({
    show: false,
    activeTab: "silver",
    dataModalBody: [],
    dataModalHeader: {}
  });
  const activeTab = arg => {
    setCond({
      activeTab: arg,
      show: true
    });
  };
  const brand = [
    {
      title: "benedict",
      img: "benedict.png",
      desc: "Benedict",
      alt: "Benedict"
    },
    {
      title: "bistecca",
      img: "bistecca-dark.png",
      desc: "Bistecca",
      alt: "Bistecca"
    },
    {
      title: "corknscrew",
      img: "corknscrew.png",
      desc: "Cork&Screw",
      alt: "Cork&Screw"
    },
    {
      title: "corknscrewcc",
      img: "cc.png",
      desc: "Cork&Screw Country Club",
      alt: "Cork&Screw Country Club"
    },
    {
      title: "caffe-millano",
      img: "caffe-milano.png",
      desc: "CAFFÈ MILANO",
      alt: "CAFFÈ MILANO"
    },
    {
      title: "loewy",
      img: "loewy.png",
      desc: "Loewy",
      alt: "Loewy"
    },
    {
      title: "pizza-milano",
      img: "pizza-milano.png",
      desc: "Pizza Milano",
      alt: "Pizza Milano"
    },
    {
      title: "the-dutch",
      img: "the-dutch.png",
      desc: "The Dutch",
      alt: "The Dutch"
    },
    {
      title: "union",
      img: "union.png",
      desc: "Union",
      alt: "Union"
    }
  ];
  const dataSendModal = {
    benedict: {
      header: {
        title: "benedict",
        imgSrc: "benedict-square.png",
        backgroundImg: "benedict-cover.jpg"
      },
      body: 
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content:
              "Claim this benefit on your birthday week at any BENEDICT outlets"
          },
          {
            title: "Cookies & Cream Cake",
            content: "Claim at any BENEDICT outlets on your birthday"
          }
        ]
        :
        tier == "golden" ?
        [
          {
            title: "Cookies & Cream Cake",
            content: "Claim at any BENEDICT outlets on your birthday"
          },

          {
            title: "10% OFF",
            content:
              "Claim this benefit on your birthday week at any BENEDICT outlets"
          }
        ]
        :
        tier == "special" ?
        [
          {
            title: "Cookies & Cream Cake",
            content: "Claim at any BENEDICT outlets on your birthday"
          },

          {
            title: "15% OFF",
            content:
              "Claim this benefit on your birthday week at any BENEDICT outlets"
          }
        ]
        :
        []
    },
    bistecca: {
      header: {
        title: "bistecca",
        imgSrc: "bistecca-square.png",
        backgroundImg: "bistecca-cover.jpg"
      },
      body: 
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content:
              "Claim this benefit on your birthday week at BISTECCA"
          },
          {
            title: "Signature Baked Alaska",
            content: "Claim at BISTECCA on your birthday"
          }
        ]
        :
        tier == "golden" ? 
        [
          {
            title: "10% OFF",
            content:
              "Claim this benefit on your birthday week at BISTECCA"
          },
          {
            title: "Signature Baked Alaska",
            content: "Claim at BISTECCA on your birthday"
          }
        ]
        :
        tier == "special" ? 
        [
          {
            title: "15% OFF",
            content:
              "Claim this benefit on your birthday week at BISTECCA"
          },
          {
            title: "Signature Baked Alaska",
            content: "Claim at BISTECCA on your birthday"
          }
        ]
        :
        []
    },
    caffeMillano: {
      header: {
        title: "caffe-millano",
        imgSrc: "caffe-millano-square.png",
        backgroundImg: "caffe-milano-cover.jpg"
      },
      body:
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content: "Claim this benefit on your birthday week at CAFFÈ MILANO"
          },
          {
            title: "WARM MELTED CHOCOLATE CAKE",
            content: "Claim at CAFFÈ MILANO on your birthday"
          }
        ]
        :
        tier == "golden" ? 
        [
          {
            title: "10% OFF",
            content: "Claim this benefit on your birthday week at CAFFÈ MILANO"
          },
          {
            title: "WARM MELTED CHOCOLATE CAKE & APEROL SPRITZ",
            content: "Claim at CAFFÈ MILANO on your birthday"
          }
        ]
        :
        tier == "special" ?
        [
          {
            title: "15% OFF",
            content: "Claim this benefit on your birthday week at CAFFÈ MILANO"
          },
          {
            title: "WARM MELTED CHOCOLATE CAKE & APEROL SPRITZ",
            content: "Claim at CAFFÈ MILANO on your birthday"
          }
        ]
        :
        []
    },
    corknscrew: {
      header: {
        title: "corknscrew",
        imgSrc: "corknscrew-square.png",
        backgroundImg: "cs-cover.jpg"
      },
      body: 
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content:
              "Claim this benefit on your birthday week at any CORK&SCREW outlets"
          },
          {
            title: "NUTELLA CAKE",
            content: "Claim at any CORK&SCREW outlets on your birthday"
          }
        ]
        :
        tier == "golden" ?
        [
          {
            title: "10% OFF",
            content:
              "Claim this benefit on your birthday week at any CORK&SCREW outlets"
          },
          {
            title: "NUTELLA CAKE",
            content: "Claim at any CORK&SCREW outlets on your birthday"
          }
        ]
        :
        tier == "special" ?
        [
          {
            title: "15% OFF",
            content:
              "Claim this benefit on your birthday week at any CORK&SCREW outlets"
          },
          {
            title: "NUTELLA CAKE",
            content: "Claim at any CORK&SCREW outlets on your birthday"
          }
        ]
        :
        []
    },
    corknscrewcc: {
      header: {
        title: "corknscrew country club",
        imgSrc: "corknscrewcc-square.png",
        backgroundImg: "cscc-cover.jpg"
      },
      body: 
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content:
              "Claim this benefit on your birthday week at CORK&SCREW COUNTRY CLUB"
          },
          {
            title: "TRIO MOLTEN CAKE",
            content: "Claim at CORK&SCREW COUNTRY CLUB on your birthday"
          }
        ]
        :
        tier == "golden" ?
        [
          {
            title: "10% OFF",
            content:
              "Claim this benefit on your birthday week at CORK&SCREW COUNTRY CLUB"
          },
          {
            title: "TRIO MOLTEN CAKE & C.C GIN & TONIC",
            content: "Claim at CORK&SCREW COUNTRY CLUB on your birthday"
          }
        ]
        :
        tier == "special" ?
        [
          {
            title: "15% OFF",
            content:
              "Claim this benefit on your birthday week at CORK&SCREW COUNTRY CLUB"
          },
          {
            title: "TRIO MOLTEN CAKE & C.C GIN & TONIC",
            content: "Claim at CORK&SCREW COUNTRY CLUB on your birthday"
          }
        ]
        :
        []
    },
    loewy: {
      header: {
        title: "loewy",
        imgSrc: "loewy-square.png",
        backgroundImg: "loewy-cover.jpg"
      },
      body: 
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content: "Claim this benefit on your birthday week at LOEWY"
          },
          {
            title: "MELTED TRIPLE CHOCOLATE CAKE",
            content: "Claim at LOEWY on your birthday"
          }
        ]
        :
        tier == "golden" ?
        [
          {
            title: "10% OFF",
            content: "Claim this benefit on your birthday week at LOEWY"
          },
          {
            title: "MELTED TRIPLE CHOCOLATE CAKE",
            content: "Claim at LOEWY on your birthday"
          }
        ]
        :
        tier == "special" ?
        [
          {
            title: "15% OFF",
            content: "Claim this benefit on your birthday week at LOEWY"
          },
          {
            title: "MELTED TRIPLE CHOCOLATE CAKE",
            content: "Claim at LOEWY on your birthday"
          }
        ]
        :
        []
    },
    pizzaMilano: {
      header: {
        title: "pizza-milano",
        imgSrc: "pizza-milano-square.png",
        backgroundImg: "pizza-milano-cover.jpg"
      },
      body: 
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content: "Claim this benefit on your birthday week at PIZZA MILANO"
          },
          {
            title: "MARGHERITA PIZZA",
            content: "Claim at PIZZA MILANO on your birthday"
          }
        ]
        :
        tier == "golden" ?
        [
          {
            title: "10% OFF",
            content: "Claim this benefit on your birthday week at PIZZA MILANO"
          },
          {
            title: "PEPPERONI PIZZA",
            content: "Claim at PIZZA MILANO on your birthday"
          }
        ]
        :
        tier == "special" ?
        [
          {
            title: "15% OFF",
            content: "Claim this benefit on your birthday week at PIZZA MILANO"
          },
          {
            title: "TRUFFLE PIZZA",
            content: "Claim at PIZZA MILANO on your birthday"
          }
        ]
        :
        []
    },
    theDutch: {
      header: {
        title: "the-dutch",
        imgSrc: "the-dutch-square.png",
        backgroundImg: "the-dutch-cover.jpg"
      },
      body: 
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content: "Claim this benefit on your birthday week at THE DUTCH"
          },
          {
            title: "CHOCOLATE MOLTEN CAKE",
            content: "Claim at THE DUTCH on your birthday"
          }
        ]
        :
        tier = "golden" ?
        [
          {
            title: "10% OFF",
            content: "Claim this benefit on your birthday week at THE DUTCH"
          },
          {
            title: "CHOCOLATE MOLTEN CAKE & GIN & TONIC ON TAP",
            content: "Claim at THE DUTCH on your birthday"
          }
        ]
        :
        tier == "special" ?
        [
          {
            title: "15% OFF",
            content: "Claim this benefit on your birthday week at THE DUTCH"
          },
          {
            title: "CHOCOLATE MOLTEN CAKE & GIN & TONIC ON TAP",
            content: "Claim at THE DUTCH on your birthday"
          }
        ]
        :
        []
    },
    union: {
      header: {
        title: "union",
        imgSrc: "union-square.png",
        backgroundImg: "union-cover.jpg"
      },
      body: 
        tier == "silver" ?
        [
          {
            title: "5% OFF",
            content:
              "Claim this benefit on your birthday week at any UNION outlets"
          },
          {
            title: "OLD FASHIONED CHOCOLATE CAKE",
            content: "Claim at any UNION outlets on your birthday"
          }
        ]
        :
        tier == "golden" ?
        [
          {
            title: "10% OFF",
            content:
              "Claim this benefit on your birthday week at any UNION outlets"
          },
          {
            title: "OLD FASHIONED CHOCOLATE CAKE & NEGRONI",
            content: "Claim at any UNION outlets on your birthday"
          }
        ]
        :
        tier == "special" ?
        [
          {
            title: "15% OFF",
            content:
              "Claim this benefit on your birthday week at any UNION outlets"
          },
          {
            title: "OLD FASHIONED CHOCOLATE CAKE & NEGRONI",
            content: "Claim at any UNION outlets on your birthday"
          }
        ]
        :
        []
    }
  };
  const modal = arg => {
    switch (arg) {
      case "benedict":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.benedict.header,
          dataModalBody: dataSendModal.benedict.body
        });
        break;

      case "bistecca":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.bistecca.header,
          dataModalBody: dataSendModal.bistecca.body
        });
        break;

      case "corknscrew":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.corknscrew.header,
          dataModalBody: dataSendModal.corknscrew.body
        });
        break;

      case "corknscrewcc":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.corknscrewcc.header,
          dataModalBody: dataSendModal.corknscrewcc.body
        });
        break;

      case "caffe-millano":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.caffeMillano.header,
          dataModalBody: dataSendModal.caffeMillano.body
        });
        break;

      case "loewy":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.loewy.header,
          dataModalBody: dataSendModal.loewy.body
        });
        break;

      case "pizza-milano":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.pizzaMilano.header,
          dataModalBody: dataSendModal.pizzaMilano.body
        });
        break;

      case "the-dutch":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.theDutch.header,
          dataModalBody: dataSendModal.theDutch.body
        });
        break;

      case "union":
        setCond({
          show: true,
          dataModalHeader: dataSendModal.union.header,
          dataModalBody: dataSendModal.union.body
        });
        break;

      case "close":
        setCond({
          show: false,
          dataModalBody: [],
          dataModalHeader: {}
        });
        break;

      default:
        return null;
    }
  };
  return (
    <>
      {brand.map((value, index) => {
        const sumDelay = index + 1;
        return (
          <Col
            md={6}
            className={"mb-3 animated fadeInUp delayp" + sumDelay}
            key={index}
          >
            <div
              className="list-group list-group-brand"
              onClick={() => modal(value.title)}
            >
              <div className="list-group-item">
                <img
                  src={images.brand[`${value.img}`]}
                  alt={value.alt}
                  className="img-fluid"
                />
                <div className="list-group-item-content">
                  <p>{value.desc}</p>
                </div>
              </div>
            </div>
          </Col>
        );
      })}
      <Modal show={cond.show} onHide={() => modal("close")} centered={true} className="modal-menu">
        <div
          className="modal-header"
          style={{

            background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${
              images.restaurant[`${cond.dataModalHeader.backgroundImg}`]
            })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "250px"
          }}
        >
           <div className="modal-close" onClick={() => modal("close")}>
            <i class="fal fa-times"></i>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column w-100 pb-4">
            <img
              src={images.brand[`${cond.dataModalHeader.imgSrc}`]}
              alt="logo"
              className="w-75px"
            />
          </div>
        </div>
        <div className="modal-body background-texture-white">
          <div className="list-group w-arrow transparent w-separator">
            {cond.dataModalBody.map((value, index) => {
              const sumDelay = index + 1;
              return (
                <div
                  className={"list-group-item animated fadeInUp delayp" + sumDelay}
                >
                  <div className="list-group-item-content">
                    <h4>{value.title}</h4>
                    <p>{value.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};
