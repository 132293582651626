import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";

export const SectionIg = ({ isVisible, linkActive, images }) => {
    let [ig, setIg] = useState([]);
    let [igId, setIgId] = useState("");
    useEffect(() => {
        switch (linkActive) {
            case "bistecca":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJYN25yZAVB2NzJuSXRhRHRISHMxWTZA2aWV4d1ZAqRC1fVEFLQlF0Q0I5Wmp4Q3BCdkYzQXBHNW9SN3VMQS1ncFlLRFRta09VTVJsYXN1ZAHotQUZAUTGNDaDVtRm1Cak4tVTFPdWEtWmlaTTdsZAzRmSgZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "bisteccajkt"))
                    );
                break;

            case "benedict":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJWb0tNOWxQRGFaSHIyQW9RQTBCcXcyTUJwNFIzZAERWMVJubEFKaVJNNFhaaDdtci1uRjM3ZAkRubHkyMk1nTFhHZA0ZA5bnRkc3JTS0lycXU3UkJXOWUzSzIxNEZArbk0xbTVUZAlJpYUtGNGhkUnN5SgZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "benedictjakarta"))
                    );
                break;

            case "caffe-millano":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJWSGFFWTZAoZA2g2aUVmT1BSZAWN4TndYN2FSMVN0TzB5bS1nMFJ2Mi15di1kSUstdDVnaE9uTVlOdzlHa1lqdjl4MXpGS2hRY0VoTTNYYVRGeG9BallWenpjQnR5RE9EeURRQ3hCR2hfV0V0U210UQZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "caffemilanojkt"))
                    );
                break;

            case "loewy":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJWVkEzcThkT1p1QjJEczdESC1URTJDRmJmZAHVEd3l6TE1VeVVUWTU5RmhjWEt0blhZAXy11UTdBeVJYQUpiX0l2WEgwZAS1UNWNtN21lZAlpvaDRXdUdYOTItdjUxQW83amZARSlFHdU5mell5eW1vTQZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "loewyjakarta"))
                    );
                break;

            case "corknscrew":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJVelZACU19zejRhaFhpakprd0YwdVhRN3lfSlhNWXlSRkI1Rms1S3FXbUxpakRDWFJaUERTVUgtRHlSY1pldjRDTW5jY1p4d3YwZAEpVUTZATalhxNWx6TnlHcUphZA0pyLVZA0c2FOOEVvczhoTmJLcQZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "corknscrew"))
                    );
                break;

            case "corknscrew-cc":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJVelZACU19zejRhaFhpakprd0YwdVhRN3lfSlhNWXlSRkI1Rms1S3FXbUxpakRDWFJaUERTVUgtRHlSY1pldjRDTW5jY1p4d3YwZAEpVUTZATalhxNWx6TnlHcUphZA0pyLVZA0c2FOOEVvczhoTmJLcQZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "corknscrew"))
                    );
                break;

            case "union":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJYdEhwb0tCYUtsS2lYTVhMUXlmb2IwLUNMUnpJaHlDbWVkSEcxTHZArbms3NmdkclpZAMHZAWTnBKcEtKSk1ZAZAV9ic2ZAxbFI3eFpZAeDY0bW9lNkQwOUtYa0hBUlZA5Y2tHdUtxZAkNFRktacTBrcEpFcQZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "unionjkt"))
                    );
                break;

            case "pierre":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJXNjZAHeTJZAWjBCcWp2ZAUpqZAGJqTTRoLXdaanR5YjdEX2pMZAFR3Vmk5NzVEYTNDNktZAODhHa0tvZAzlLbXl6b0FyQ201NEd4V1N0S1lITlRBYWQxendWZAjBac1IybkhTUlNyX095Nkg4V04tV0RERQZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "pierre.jkt"))
                    );
                break;

            case "pizza-milano":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJVOUxVbzFSeDJMbVg5d252S1VnamJFV01EX1dUZAHlIVnU5SkIya0VaeThrdWVvT0pkOVJubDVxM3p0ZAGF4YkUyWkJnSjl2bzNmN1lmb0J0QVhaMVc5eVJvWmpjcnFWZAV9qVmIxVkNVYmJxTGY0MwZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "pizzamilanojkt"))
                    );
                break;

            case "the-dutch":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJYd1F3WHpMUzlpQkI4U3lHdUdMSC14aWdhWVUzYUlKV0tCcGlzQTNBemJoa2NfTkJ3RW1uRHdjcEVrTGdhLWZAQVDB2TGRjWDQwd25weTJUOTJqeU1uM1NMR2JZARlhacnE2T1JPWmgydHduNGFYOQZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "thedutchjakarta"))
                    );
                break;

            case "roma":
                fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink&access_token=IGQVJVNEdjSFdiS2xHX3NaWFhFZA1BkUXIwYldjMDAzZA2JDcHNRYV9IN1lhSUdmRU5VYzZAHNWQ2dUlFeU93ak93SEVNYWNXMmdKcEs5ZA1cxRTMwRHFoOTdRRzJDRV8zVFpCS3FLRjJabC1FTm53VkJxZAwZDZD`
                )
                    .then((response) => response.json())
                    .then(
                        (data) => setIg((ig = data.data)),
                        setIgId((igId = "romaosteriabar"))
                    );
                break;

            default:
                return null;
        }
    }, []);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3.5,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2.5,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2.5,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.25,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 2.25,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const effect = isVisible ? "fadeInUp" : "";
    return ig == undefined ? (
        <></>
    ) : (
        <section className="py-main bg-white">
            <Container>
                <div className={"heading animated " + effect + " delayp1"}>
                    <h2 className="text-uppercase">@{igId}</h2>
                </div>
                <Slider {...settings} className="carousel-instagram">
                    {ig?.length === 0
                        ? console.log("wait")
                        : ig?.map((value, index) => {
                            return (
                                <div className="carousel-item" key={index}>
                                    <a href={value.permalink} target="_blank">
                                        <div
                                            className="card card-ig"
                                            style={{
                                                backgroundImage: `url(${value.media_url})`,
                                                backgroundPosition: "center",
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                        >
                                            <img
                                                src={images.common["img_transparent-square.png"]}
                                                className="img-fluid"
                                                alt="img-transparent"
                                            />
                                            <div className="card-overlay-text">
                                                <p>{value.caption}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            );
                        })}
                </Slider>
            </Container>
        </section>
    );
};