import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { PageContext } from "./../context/PageContext";

const Footer = () => {
    const { footerCase } = useContext(PageContext);
    const year = new Date().getFullYear()
    return (
        // <footer className={footerCase ? "footer" : "footer hidden"} id="footer">
        <footer className="footer">
            <div className="footer-top">
                <div className="container">
                <ul>
                    <li><Link to="/houserules">House Rules</Link></li>
                    <li><Link to="/faq">FAQ</Link></li>
                    <li><Link to="/tnc"><span className="d-none d-md-inline-block">Terms &
                        Conditions</span><span className="d-inline-block d-md-none">T&C</span></Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <span>© {year} The Union Group. All rights reserved.</span>
            </div>
        </footer>
    )
}

export default Footer;